import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SoleContact, SoleContactForm, SoleContactService, ToastService } from 'taxtank-core';
import { BaseFormComponent } from '@shared/component/base/base-form.component';

@Component({
  selector: 'app-sole-contact-form',
  templateUrl: './sole-contact-form.component.html',
  styleUrls: ['./sole-contact-form.component.scss']
})
export class SoleContactFormComponent extends BaseFormComponent {
  @Input() contact: SoleContact;
  @Output() saved: EventEmitter<SoleContact> = new EventEmitter();
  form: SoleContactForm;

  constructor(
    private soleContactService: SoleContactService,
    private toastService: ToastService
  ) {
    super();
  }

  buildForm(): void {
    this.form = new SoleContactForm(this.contact);
  }

  save(contact: SoleContact): void {
    if (contact.id) {
      this.soleContactService.update(contact).subscribe(() => {
        this.saved.emit(contact);
        this.toastService.success('Business updated');
      });
    } else {
      this.soleContactService.add(contact).subscribe(() => {
        this.saved.emit(contact);
        this.toastService.success('Business created');
      });
    }
  }
}
