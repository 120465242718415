import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CalculationFormItem } from 'taxtank-core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { plainToClass } from 'class-transformer';

/**
 * Calculation form component
 */
@Component({
  selector: 'app-calculation-form',
  templateUrl: './calculation-form.component.html',
  styleUrls: ['./calculation-form.component.scss']
})
export class CalculationFormComponent implements OnInit, OnChanges {
  // menu trigger to interact with menu inside component
  @Input() menuTrigger: MatMenuTrigger;
  @Input() title: string;
  // list of items. based on will be created form controls
  @Input() formItems: CalculationFormItem[];
  // control from outside component for what will be calculated final amount
  @Input() control: AbstractControl;
  // emit event when calculation form control value will be changed
  @Output() controlChanged: EventEmitter<CalculationFormItem> = new EventEmitter<CalculationFormItem>();
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnChanges(): void {
    if (!this.form) {
      return;
    }

    this.toggleControlDisability(this.formItems);
  }

  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * Build calculation form
   */
  buildForm(): void {
    this.form = this.fb.group({});
    this.formItems.forEach((formItem: CalculationFormItem) => {
      this.form.addControl(formItem.name, new UntypedFormControl({
        value: '',
        disabled: formItem.isDisabled
      }, formItem.validators));
    });
  }

  /**
   * Toggle form controls 'disabled' property
   * @param updatedCategories
   */
  toggleControlDisability(updatedCategories: CalculationFormItem[]): void {
    updatedCategories.forEach((capitalCostsFormItem: CalculationFormItem) => {
      if (!capitalCostsFormItem.dependentFields) {
        capitalCostsFormItem.isDisabled ?
          this.form.get(capitalCostsFormItem.name).disable() : this.form.get(capitalCostsFormItem.name).enable();
      }
    });
  }

  /**
   * Take only number-type values and calculate their sum
   */
  @Input() submit(): void {
    this.control.setValue(Object.values(this.form.getRawValue())
      .filter((formValue: any) => typeof formValue === 'number')
      .reduce((sum: number, cur: number) => sum + cur));
  }

  /**
   * Emit control value
   * @param controlName
   * @param value
   */
  emitControlChanged(controlName: string, value: boolean): void {
    this.controlChanged.emit(plainToClass(CalculationFormItem, {name: controlName, value}));
  }
}
