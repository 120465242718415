import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MenuItem } from '@shared/component/layout/menu/menu-item';
import { AuthService, BankTransactionCollection, User, UserService } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';
import { MessagesEnum } from './messages.enum';
import { takeUntil } from 'rxjs/operators';

/**
 * Main app menu component
 */
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends BaseComponent implements OnInit {
  @Input() menuItems: MenuItem[] = [];
  @Input() isCollapsed: boolean;
  filteredMenuItems: MenuItem[] = [];
  bankTransactions: BankTransactionCollection;
  messages: typeof MessagesEnum = MessagesEnum;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.userService.getFirst()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.filter(user);
        this.changeDetectorRef.markForCheck();
      });
  }

  /**
   * filter menu/submenu items by user roles
   */
  filter(user: User): void {
    this.filteredMenuItems = this.menuItems.slice();

    for (let i = this.filteredMenuItems.length - 1; i >= 0; i--) {
      const menuItem = this.filteredMenuItems[i];
      if (menuItem.roles.length && !user.hasRoles(menuItem.roles)) {
        this.filteredMenuItems.splice(i, 1);
      }

      for (let index = menuItem.subMenuItems.length - 1; index >= 0; index--) {
        const submenuItem = menuItem.subMenuItems[index];
        if (submenuItem.roles.length && !user.hasRoles(submenuItem.roles)) {
          menuItem.subMenuItems.splice(index, 1);
        }
      }
    }
  }

  /**
   * Logout user from app
   */
  logout() {
    this.authService.logoutFront();
  }
}
