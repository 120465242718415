import { NgModule } from '@angular/core';
import { AccountSecurityModule } from '@shared/component/account-security/account-security.module';
import { ComparisonModule } from '@shared/component/comparison/comparison.module';
import { ConfirmDialogModule } from '@shared/component/confirm-dialog/confirm-dialog.module';
import { InnerCardModule } from '@shared/component/inner-card/inner-card.module';
import { LayoutModule } from '@shared/component/layout/layout.module';
import { NavigationModule } from '@shared/component/navigation/navigation.module';
import { PreloaderModule } from '@shared/component/preloader/preloader.module';
import { TankIndicatorsModule } from '@shared/component/tank-indicators/tank-indicators.module';
import { PhotoFormModule } from '@shared/component/photo/photo-form/photo-form.module';
import { ValidationErrorModule } from '@shared/component/validation-error/validation-error.module';
import { ImagePopupModule } from '@shared/component/image-popup/image-popup.module';
import { BadgeModule } from '@shared/component/badge/badge.module';
import { ChartsModule } from '@shared/component/charts/charts.module';
import { ProgressBarModule } from '@shared/component/progress-bar/progress-bar.module';
import { ImageModule } from '@shared/component/image/image.module';
import { DocumentModule } from '@shared/component/document/document.module';
import { DataCardModule } from '@shared/component/data-card/data-card.module';
import { CardModule } from '@shared/component/card/card.module';
import { DividerComponent } from './divider/divider.component';
import { SubCodeModule } from '@shared/component/sub-code/sub-code.module';
import { ExportModule } from '@shared/component/export/export.module';
import { VideoGalleryDialogModule } from '@shared/component/video-gallery-dialog/video-gallery-dialog.module';
import { CopyToClipboardModule } from '@shared/component/copy-to-clipboard/copy-to-clipboard.module';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { PaginatorComponent } from './paginator/paginator.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PanelModule } from '@shared/component/panel/panel.module';

/**
 * Used to activate desired Swiper modules
 * https://swiperjs.com/angular#usage
 */
SwiperCore.use([Pagination, Navigation]);

@NgModule({
  imports: [
    AccountSecurityModule,
    BadgeModule,
    ComparisonModule,
    ConfirmDialogModule,
    InnerCardModule,
    ImagePopupModule,
    LayoutModule,
    NavigationModule,
    PreloaderModule,
    TankIndicatorsModule,
    PhotoFormModule,
    ValidationErrorModule,
    ChartsModule,
    ProgressBarModule,
    ImageModule,
    DocumentModule,
    DataCardModule,
    CardModule,
    SubCodeModule,
    ExportModule,
    VideoGalleryDialogModule,
    CopyToClipboardModule,
    SwiperModule,
    PanelModule,
    PaginatorComponent
  ],
  exports: [
    AccountSecurityModule,
    BadgeModule,
    ComparisonModule,
    ConfirmDialogModule,
    InnerCardModule,
    ImagePopupModule,
    LayoutModule,
    NavigationModule,
    PreloaderModule,
    TankIndicatorsModule,
    PhotoFormModule,
    ValidationErrorModule,
    ChartsModule,
    ProgressBarModule,
    ImageModule,
    DocumentModule,
    DataCardModule,
    CardModule,
    SubCodeModule,
    ExportModule,
    DividerComponent,
    VideoGalleryDialogModule,
    CopyToClipboardModule,
    SwiperModule,
    PanelModule,
    PaginatorComponent,
    TermsAndConditionsComponent
  ],
  declarations: [DividerComponent, TermsAndConditionsComponent]
})
export class ComponentModule { }
