import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewPortService } from '@shared/services/view-port.service';

@Component({
  selector: 'app-sidenav-toggle-button',
  templateUrl: './sidenav-toggle-button.component.html',
  styleUrls: ['./sidenav-toggle-button.component.scss']
})
export class SidenavToggleButtonComponent {
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() isSidebarOpened: boolean;
  @Input() isBurger: boolean;

  constructor(
    public viewPortService: ViewPortService
  ) {}
}
