import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { BaseComponent } from '@shared/component/base/base.component';
import { UserService, YoutubeService, YoutubeVideoInterface } from 'taxtank-core';
import { ViewPortService } from '@shared/services/view-port.service';

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss']
})
export class VideoGalleryComponent extends BaseComponent implements OnInit {
  @ViewChildren('videoElement') videoElement: ElementRef;
  playlist: YoutubeVideoInterface[];
  selectedVideo: YoutubeVideoInterface;
  videoHeight = 235;

  constructor(
    private tutorialVideoService: YoutubeService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    public viewPortService: ViewPortService,
  ) {
    super();
  }

  ngOnInit() {
    this.tutorialVideoService.getTutorials(this.userService.getCacheFirst()).subscribe((playlist: YoutubeVideoInterface[]) => {
      this.playlist = playlist;
      this.selectedVideo = this.playlist[0];
    });
  }

  /**
   * @TODO might be used later
   */
  setHeight(): void {
    const proportion = 2;
    this.cdr.detectChanges();
    this.videoHeight = this.videoElement.nativeElement.offsetWidth / proportion;
  }

  selectVideo(video: YoutubeVideoInterface) {
    this.selectedVideo = video;
  }
}
