<ng-container [appPreloader]="[endpoints.SOLE_CONTACTS_POST, endpoints.SOLE_CONTACTS_PUT]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<form [formGroup]="form" (ngSubmit)="submit()" appFormErrorScroll>
  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-form-field>
        <mat-label>Contact name</mat-label>
        <input matInput formControlName="name" placeholder="Enter contact name">
        <mat-error>
          <app-validation-error [errors]="form.get('name').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field>
        <mat-label>ABN</mat-label>
        <input matInput formControlName="abn" placeholder="ABN is recommended for invoices over $1000">
        <mat-error>
          <app-validation-error [errors]="form.get('abn').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput formControlName="firstName" placeholder="Enter first name">
        <mat-error>
          <app-validation-error [errors]="form.get('firstName').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field>
        <mat-label>Last name</mat-label>
        <input matInput formControlName="lastName" placeholder="Enter last name">
        <mat-error>
          <app-validation-error [errors]="form.get('lastName').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" placeholder="Enter email">
        <mat-error>
          <app-validation-error [errors]="form.get('email').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <app-phone-input formControlName="phone" ></app-phone-input>
    </div>

    <div class="col-12">
      <app-address-input formControlName="address" [hideCountryField]="false"></app-address-input>
    </div>
  </div>
</form>
