import { NgModule } from '@angular/core';
import { SubscriptionChangeDialogComponent } from '@client/shared/components/subscription/subscription-change-dialog/subscription-change-dialog.component';
import { SubscriptionItemComponent } from '@client/shared/components/subscription/subscription-item/subscription-item.component';
import { CommonModule } from '@angular/common';
import { SubscriptionPaymentSuccessDialogComponent } from './subscription-payment-success-dialog/subscription-payment-success-dialog.component';
import { SubscriptionPaymentFailureDialogComponent } from './subscription-payment-failure-dialog/subscription-payment-failure-dialog.component';
import { MaterialModule } from '@shared/material/material.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '@shared/pipe/pipe.module';
import { ConfirmPopupModule } from '@shared/component/form-controls/confirm-popup/confirm-popup.module';
import { PreloaderModule } from '@shared/component';
import { InputNumberModule } from 'primeng/inputnumber';
import { PromoCodeComponent } from '@client/shared/components/subscription/promo-code/promo-code.component';
import { AcceptPromocodeComponent } from './promo-code/accept-promocode/accept-promocode.component';

/**
 * @TODO Alex why not imported in parent shared module?
 */
@NgModule({
  declarations: [
    SubscriptionChangeDialogComponent,
    SubscriptionItemComponent,
    SubscriptionPaymentSuccessDialogComponent,
    SubscriptionPaymentFailureDialogComponent,
    PromoCodeComponent,
    AcceptPromocodeComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    DirectivesModule,
    FormsModule,
    PipeModule,
    PreloaderModule,
    ConfirmPopupModule,
    InputNumberModule
  ],
  exports: [
    SubscriptionChangeDialogComponent,
    SubscriptionPaymentSuccessDialogComponent,
    PromoCodeComponent,
    AcceptPromocodeComponent
  ],
})
export class SubscriptionModule { }
