import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  PropertyCollection,
  PropertyService,
  ServicePrice,
  ServiceProduct,
  ServiceProductIdEnum,
  ServiceSubscription,
  ServiceSubscriptionItem
} from 'taxtank-core';
import range from 'lodash/range';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-subscription-item',
  templateUrl: './subscription-item.component.html',
  styleUrls: ['./subscription-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionItemComponent implements OnChanges, OnInit {
  @Input() price: ServicePrice;
  @Input() currentSubscription: ServiceSubscription;
  @Input() preselectedServiceProductId?: ServiceProductIdEnum;
  @Output() selected: EventEmitter<ServiceSubscriptionItem> = new EventEmitter<ServiceSubscriptionItem>();
  @Output() deselected: EventEmitter<ServiceSubscriptionItem> = new EventEmitter<ServiceSubscriptionItem>();
  @Output() updated: EventEmitter<ServiceSubscriptionItem> = new EventEmitter<ServiceSubscriptionItem>();
  min: number;
  isSelected: boolean;
  isDisabled: boolean;
  item: ServiceSubscriptionItem;
  product: ServiceProduct;
  quantityRange: number[];

  constructor(
    private propertyService: PropertyService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.item = this.price.toSubscriptionItem();
    this.product = this.item.price.product;
    this.quantityRange = range(this.product.minQty, this.product.maxQty + 1);
    this.min = this.product.minQty;
    this.isDisabled = !this.product.isAddon;

    if (this.product.isProperties()) {
      this.propertyService.get()
        .pipe(map((properties: PropertyCollection) => properties.getActiveProperties()))
        .subscribe((properties: PropertyCollection) => {
          this.min = Math.max(this.product.minQty, properties.length);
          // preselect one more property than the user currently has in case if user has property subscription
          this.item.quantity = this.currentSubscription.getRecommendedPropertiesQty(this.item, properties.length);
          this.changeDetectorRef.markForCheck();
        });
    }
  }

  ngOnInit() {
    // Select subscription item if current subscription already has related item or id of product price was provided
    if (!this.product.isAddon || this.currentSubscription.hasItem(this.item) || this.preselectedServiceProductId === this.item.price.id) {
      this.isSelected = true;
      this.toggle();
    }
  }

  update(): void {
    this.updated.emit(this.item);
  }

  toggle(): void {
    this.isSelected ? this.selected.emit(this.item) : this.deselected.emit(this.item);
  }
}
