import { Directive, OnDestroy } from '@angular/core';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';

/**
 * A directive that "fixes" the MatAutocomplete options to its input.
 * This is a known Angular issue where if the autocompleter is inside a dialog, its options are not assigned to the input when scrolling.
 * https://github.com/angular/components/issues/10079
 * Don't forget to import this directive in the module where you are using the autocomplete in mat-dialog
 */
@Directive({
  selector: 'input[matAutocomplete]',
})
export class AutocompletePositionDirective implements OnDestroy {
  constructor(
    private readonly matAutocompleteTrigger: MatAutocompleteTrigger,
  ) {
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  private scrollEvent = (): void => {
    if (this.matAutocompleteTrigger == null) {
      return;
    }
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.updatePosition();
    }
  }
}
