import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/component/base/base.component';
import { UntypedFormArray } from '@angular/forms';
import { propertyAddressAsyncValidator } from '@shared/validators/property-address.async.validator';
import { Collection, PropertyCategory, PropertyCategoryService, PropertyService, PropertyAddForm } from 'taxtank-core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-property-add-form',
  templateUrl: './property-add-form.component.html',
  styleUrls: ['./property-add-form.component.scss']
})
export class PropertyAddFormComponent extends BaseComponent implements OnInit {
  @Input() form: PropertyAddForm = new PropertyAddForm();
  categories: Collection<PropertyCategory>;
  now: Date = new Date();

  constructor(
    private propertyCategoryService: PropertyCategoryService,
    private propertyService: PropertyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.propertyCategoryService.get()
      .pipe(takeUntil(this.destroy$))
      .subscribe((categories: Collection<PropertyCategory>) => {
        this.categories = categories.sortBy('name');
      });

    this.form.get('address').setAsyncValidators(propertyAddressAsyncValidator(this.propertyService.get()));
  }

  get sharesArray(): UntypedFormArray {
    return this.form.get('shares') as UntypedFormArray;
  }

  get forecastsArray(): UntypedFormArray {
    return this.form.get('forecasts') as UntypedFormArray;
  }
}
