<header mat-dialog-title>
  <div class="row">
    <div class="col-2 col-md-2 text-right">
      <i class="icon-alert ic-white"></i>
    </div>
    <div class="col-8 col-md-9">
      <div class="f-column">
        <h1 class="c-white">Action Required</h1>
        <p class="message-info">
            We have been unable to process your payment. But don't worry, we’ve got your back! Just select the ‘Update
            Subscription' button to update and confirm your payment details and pick up right were you left off.
        </p>
      </div>
    </div>
    <div class="col-2 col-md-1">
      <button mat-dialog-close class="icon-close ic-white fs-big"></button>
    </div>
  </div>
</header>

<div mat-dialog-content>
  <div class="banner-image"></div>
  <div class="content-wrapper">
    <ul class="advantages-list">
      <li>
        <i class="icon icon-check"></i>
        <div class="advantage">
          <b>Pay less tax.</b> <br>
          Claim every possible deduction with live bank feeds & built in smart tools
        </div>
      </li>
      <li>
        <i class="icon-check"></i>
        <div class="advantage">
          <b>Easy Tax Preparation.</b> <br>
          No more end of year hassles or surprises. Know your tax position in real time.
        </div>
      </li>
      <li>
        <i class="icon-check"></i>
        <div class="advantage">
          <b>Safe Storage.</b> <br>
          Protect your documents year after year and safeguard against ATO audit
        </div>
      </li>
    </ul>
    <div class="button-area pt-20">
      <button class="primary" (click)="openSubscriptionDialog()">Update subscription</button>
    </div>
  </div>
</div>
