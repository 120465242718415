import { Directive, HostListener, Input } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

/**
 * Directive that apply input value to passed mat table data source
 */
@Directive({
  selector: 'input[appTableSearch]'
})
export class TableSearchDirective {
  // Material table data source instance
  @Input() appTableSearch: MatTableDataSource<object>;
  // Filter predicate which used to rewrite MatDataSource.filterPredicate property
  @Input() filterPredicate;

  @HostListener('input', ['$event.target.value']) onInput(searchQuery: string): void {
    if (this.filterPredicate) {
      this.appTableSearch.filterPredicate = this.filterPredicate;
    }

    this.appTableSearch.filter = searchQuery.trim().toLowerCase();
  }
}
