<ng-container [appPreloader]="[endpoints.USER_STATUS_PUT]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<div mat-dialog-title>
  <div class="dialog-caption">Welcome to TaxTank</div>
  <div class="dialog-subscription">
    We're really excited that you have joined <b>TaxTank</b>.
    It takes just 2 simple steps to onboard your {{ user.isAccountant() ? 'firm' : 'advisor group'}}
  </div>
  <button mat-dialog-close class="icon-close"></button>
</div>

<div mat-dialog-content>
  <div class="banner-image"></div>
  <div class="content-wrapper">
    <ul class="advantages-list">
      <li class="align-v-center">
        <img src="assets/img/icons/home-office.png" class="icon">
        <span class="fw-700 c-secondary ml-15">Step 1: Add Firm Details.</span>
      </li>
      <li class="align-v-center">
        <img src="assets/img/icons/low-startup-cost.png" class="icon">
        <span class="fw-700 c-secondary ml-15">Step 2: Invite your team.</span>
      </li>
      <li class="align-v-center">
        <img src="assets/img/icons/passive-income.png" class="icon">
        <span class="fw-700 c-secondary ml-15">Step 3: Invite your clients.</span>
      </li>
    </ul>
    <div>Of course, you can always skip and get to the good stuff straight away!</div>
  </div>
</div>

<div mat-dialog-actions>
  <div class="align-h-right">
    <button class="primary" (click)="startOnboarding()">Onboard now</button>
    <button class="primary inverted" (click)="skipOnboarding()">Skip</button>
  </div>
  <div class="align-h-center mt-10">
    <img src="assets/img/icons/logo.svg" title="logo" alt="logo"/>
  </div>
</div>
