import { Component, Input, OnInit } from '@angular/core';

declare type ViewType = 'arrow' | 'textArrow';
declare type TextFormat = 'textShort' | 'textFull';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit {
  @Input() a: number;
  @Input() b: number;

  // The value depending on which the arrow icon will go up or down
  @Input() rateValue: number;
  // View of component depends on input type
  @Input() type: ViewType;
  // Reverse arrow colors
  @Input() isReversed = false;
  @Input() textFormat: TextFormat;
  // Text displaying before comparison result
  @Input() preText: string;
  rateText: 'up' | 'down';

  ngOnInit(): void {
    if (this.rateValue) {
      return;
    }

    this.rateValue = this.a ? (this.a - this.b) / this.a * 100 : 0;
    this.rateText = (this.rateValue > 0 && !this.isReversed) || (this.rateValue < 0 && this.isReversed) ? 'up' : 'down';
  }
}
