<!-- @Todo TT-2340 Mikhail Create common component with markup and use in each banner via slots -->
<header mat-dialog-title>
  <div>
    <h1 class="c-white mb-15">Upgrade with Sole Tank</h1>
    <span class="fs-big">
      To manage your sole trader businesses with TaxTank please upgrade your subscription today.
    </span>
  </div>
  <button mat-dialog-close class="icon-close"></button>
</header>

<div mat-dialog-content>
  <div class="banner-image"></div>
  <div class="content-wrapper">
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Monitor business performance</span>
    </div>
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Create and track invoices & expenses</span>
    </div>
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Real-time Reporting</span>
    </div>

    <div class="pv-10">
      <b>$9/m</b> for up to 6 businesses
    </div>

    <button class="bg-secondary c-white" (click)="openSubscriptionDialog()">Upgrade now</button>
  </div>
</div>
