<!-- @Todo TT-2340 Mikhail Create common component with markup and use in each banner via slots -->
<header mat-dialog-title>
  <div>
    <h1 class="c-white mb-15">Property limit reached</h1>
    <span class="fs-big">
      Your have reached the property limit in your current subscription. To continue, please upgrade by adding another property.
    </span>
  </div>
  <button mat-dialog-close class="icon-close ic-white"></button>
</header>

<div mat-dialog-content>
  <div class="banner-image"></div>
  <div class="content-wrapper">
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Smart Forecasting</span>
    </div>
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Automated Deprecation</span>
    </div>
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Real-time Reporting</span>
    </div>

    <div class="plan-properties-settings-title pv-10">
      From <b>$15/m</b> including 5 properties
    </div>

    <button class="bg-secondary c-white" (click)="openSubscriptionDialog()">Upgrade now</button>
  </div>
</div>
