import { FormGroupDirective } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractForm } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Abstract component should be extended by components which contains form extended AbstractForm
 * @TODO Alex: work with generics for form and/or model classes
 */
@Component({
  template: ''
})
export abstract class BaseFormComponent extends BaseComponent implements OnInit {
  @ViewChild(FormGroupDirective) formRef: FormGroupDirective;
  @Output() saved: EventEmitter<object | void> = new EventEmitter<object | void>();
  abstract form: AbstractForm<object>;

  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * @param data custom data to submit
   */
  submit(data: object = {}): void {
    const formValue: object = this.form.submit();

    if (!formValue) {
      return;
    }

    this.save(formValue, data);
  }

  abstract buildForm(): void;

  /**
   * @TODO Alex: implement method here + add abstract methods add/update and call them here + show toasts and emit events like:
   * add() {service.add().subscribe(() => super.add()} or something like this
   */
  abstract save(formValue: object, data: object): void;
}
