<!-- Table export case -->
<ng-container *ngIf="!isLoading">
  <!-- Single export button -->
  <ng-container *ngIf="formats.length === 1; else multipleExport">
    <button (click)="export(formats[0])" class="icon-download print {{isBasicViewType ? 'basic' : 'primary'}}">
      <ng-container *ngIf="customButtonLabel">
        <div class="col-12 col-sm">
          {{customButtonLabel}}
        </div>
      </ng-container>

      <ng-container *ngIf="!customButtonLabel">
        <div class="col-12 col-sm">
          {{isBasicViewType ? fileFormats[formats[0]] : 'Export as ' + fileFormats[formats[0]]}}
        </div>
      </ng-container>
    </button>
  </ng-container>

  <!-- Multiple export mat-menu -->
  <ng-template #multipleExport>
    <button [matMenuTriggerFor]="menu"
            class="icon-download align-v-center align-h-space-between pr-5 {{isBasicViewType ? 'basic' : 'gradient'}}">
      Export
      <i class="icon-caret-down ic-primary is-big ml-10"></i>
    </button>

    <mat-menu #menu="matMenu">
      <button *ngFor="let format of formats"
              mat-menu-item
              (click)="export(format)">
        {{isBasicViewType ? format : "Export as " + format}}
      </button>
    </mat-menu>
  </ng-template>
</ng-container>

<!-- HTML export case -->
<ng-container *ngIf="isLoading">
  <button [disabled]="isLoading" (click)="export()" [ngClass]="buttonClass" class="icon-download print">
    {{ isLoading ? 'Downloading...' : 'Export as PDF' }}
  </button>
</ng-container>
