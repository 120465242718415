<form [formGroup]="form" class="transparent">
  <div class="row">
    <div *ngIf="!hideTypeField" class="col-4">
      <mat-form-field>
        <mat-label>Current address</mat-label>
        <mat-select formControlName="type">
          <mat-option [value]="addressTypes.STREET">Street</mat-option>
          <mat-option [value]="addressTypes.POSTAL">Postal</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Search autocomplete -->
    <div [class]="hideTypeField ? 'col-12' : 'col-8'">
      <mat-form-field [hideRequiredMarker]="hideRequiredMarker">
        <mat-label>Street Address</mat-label>

        <input matInput
               [matAutocomplete]="autocomplete"
               [errorStateMatcher]="errorStateMatcher"
               formControlName="searchQuery"
               placeholder="Address"
               (blur)="onTouched()"/>

        <div matSuffix>
          <ng-container [appPreloader]="[endpoints.PROPERTIES_SUGGESTIONS_GET, endpoints.CORELOGIC_TOKEN_GET]"
                        [preloaderType]="preloaderTypes.ICON"
                        [preloaderSize]="20">
          </ng-container>
        </div>

        <mat-autocomplete #autocomplete="matAutocomplete"
                          (optionSelected)="form.onSelectSuggestion($event.option.value)"
                          [displayWith]="displaySuggestionFn">
          <ng-container *ngIf="(suggestions$ | async) as suggestions">
            <mat-option *ngFor="let suggestion of suggestions" [value]="suggestion">
              {{suggestion.suggestion}}
            </mat-option>

            <mat-option *ngIf="!suggestions.length">
              Add manually
            </mat-option>
          </ng-container>
        </mat-autocomplete>

        <mat-error>
          <app-validation-error *ngIf="form.get('searchQuery').invalid"
                                [errors]="form.get('searchQuery').errors">
          </app-validation-error>
          <div *ngIf="form.hasError('address')">{{form.errors?.address}}</div>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Manual fields -->
    <ng-container *ngIf="form.isManual">
      <!-- Unit number -->
      <div class="col-4">
        <mat-form-field>
          <mat-label>Unit No.</mat-label>
          <input matInput formControlName="unitNumber" type="number" placeholder="Enter unit number">
        </mat-form-field>
      </div>

      <!-- Address -->
      <div class="col-4">
        <mat-form-field>
          <mat-label>Street No.</mat-label>
          <input matInput formControlName="streetNumber" placeholder="Enter street number">
          <mat-error>
            <app-validation-error [errors]="form.get('streetNumber').errors"></app-validation-error>
            <!-- @TODO Alex/Mikhail/Artem (TT-2471): implement validators with built-in messages -->
            <ng-container *ngIf="form.get('streetNumber').hasError('pattern')">Spaces are not allowed</ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput formControlName="street" placeholder="Enter street name">
          <mat-error>
            <app-validation-error [errors]="form.get('street').errors"></app-validation-error>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- City -->
      <div class="col-4">
        <mat-form-field>
          <mat-label>Suburb</mat-label>
          <input matInput formControlName="city" placeholder="Enter locality name">
          <mat-error>
            <app-validation-error [errors]="form.get('city').errors"></app-validation-error>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- State -->
      <div class="col-4">
        <mat-form-field>
          <mat-label>State</mat-label>

          <mat-select *ngIf="form.isAustraliaSelected(); else stateInput" formControlName="state" placeholder="Select state">
            <mat-option *ngFor="let state of (states | keyvalue)" [value]="state.key">{{state.value}}</mat-option>
          </mat-select>

          <ng-template #stateInput>
            <input matInput formControlName="state" placeholder="Enter state">
          </ng-template>

          <mat-error>
            <app-validation-error [errors]="form.get('state').errors"></app-validation-error>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Postal code -->
      <div class="col-4">
        <mat-form-field>
          <mat-label>Postal code</mat-label>
          <input matInput formControlName="postcode" placeholder="Postal Code">
          <mat-error>
            <app-validation-error [errors]="form.get('postcode').errors"></app-validation-error>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Country -->
      <div *ngIf="!hideCountryField" class="col-4">
        <app-country-autocomplete formControlName="country"></app-country-autocomplete>
      </div>
    </ng-container>
  </div>
</form>
