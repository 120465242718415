<div class="d-flex">
  <ng-container [ngSwitch]="viewType">
    <button *ngSwitchCase="'button'"
            class="gradient print icon-download w-100"
            (click)="export()">
      Export as PDF
    </button>
    <button *ngSwitchCase="'icon'"
            class="icon-download"
            matTooltip="Export data as PDF"
            (click)="export()">
    </button>

    <ng-container *ngSwitchCase="'text'">
      <div *ngIf="tables"
           class="pointer p-10 c-secondary"
           (click)="export()">
        {{label}}
      </div>

      <div *ngIf="collection"
           class="pointer p-10 c-secondary"
           (click)="exportToXLSX()">
        {{label}}
      </div>
    </ng-container>
  </ng-container>
</div>
