import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';
import { TankTypeEnum, User, UserService } from 'taxtank-core';
import { NgControl } from '@angular/forms';
import { MatLegacySelect } from '@angular/material/legacy-select';

/**
 * Tank type select / radio button form control
 */
@Component({
  selector: 'app-tank-type-select',
  templateUrl: './tank-type-select.component.html',
  styleUrls: ['./tank-type-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TankTypeSelectComponent extends AbstractControlComponent<number> implements OnInit, AfterContentChecked, AfterViewInit {
  @ViewChild('matSelect') matSelect: MatLegacySelect;
  @Input() mode: 'select' | 'radioIcons' | 'radioLabels' = 'select';
  @Input() placeholder = 'Select Tank Type';
  @Input() label = 'Tank Type';
  @Input() emptyOptionLabel = 'All Tanks';
  @Input() hasEmptyOption = false;
  @Input() types: TankTypeEnum[] = [TankTypeEnum.WORK, TankTypeEnum.PROPERTY, TankTypeEnum.SOLE];
  @Input() hideHoldingTank: boolean;
  tankTypes: typeof TankTypeEnum = TankTypeEnum;
  user: User;
  tankTypeList = TankTypeEnum;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private userService: UserService
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    this.user = this.userService.getCacheFirst();
  }

  /**
   * Preselect the option when we have one tank.
   * detach use, which simply cancels the checks on the current view
   */
  ngAfterViewInit() {
    if (this.matSelect?.options.length === 1) {
      this.ngControl.control.setValue(this.matSelect.options.first.value)
      this.changeDetectorRef.detach();
    }
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }
}
