<div mat-dialog-title>
  <div class="dialog-caption">Success</div>
  <button mat-dialog-close class="icon-close"></button>
</div>

<div mat-dialog-content class="bank-connections-dialog-content">
  <div class="success-message">
      <ng-container *ngIf="areBankAccountsAdded">
        <h4>Bank accounts were successfully added.</h4>

        <p *ngIf="connection.externalId">
          We will notify you once available transactions have been imported (this usually takes a few minutes).
        </p>

        <p *ngIf="!connection.externalId">
          You can import your bank transactions from CSV file.
        </p>
      </ng-container>

      <ng-container *ngIf="!areBankAccountsAdded">
        <h4>We are importing data from your bank.</h4>

        <p>Usually it takes a few minutes. We will notify you as soon as it's ready.</p>
      </ng-container>
    </div>
</div>

<div mat-dialog-actions>
  <button mat-dialog-close class="primary">Finish</button>
</div>
