<ng-container [appPreloader]="[endpoints.CLIENT_INCOME_TYPES_POST]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<div mat-dialog-title class="align-h-center">
  <div class="c-white mb-15">Welcome to TaxTank</div>
</div>

<div mat-dialog-content>
  <div class="message c-secondary text-center mb-25">
    We're really excited that you joined <b>TaxTank</b>.
    <div>To get started, simply select the Tanks you need and start your 14 day free trial.</div>
  </div>

  <form class="transparent" (ngSubmit)="submit()">
    <div class="mb-25">
      <app-toggler-group>
        <app-toggler ngModel
                     name="work"
                     (ngModelChange)="toggle(priceList.WORK_MONTHLY, $event)"
                     label="Work Tank"
                     description="Salary & wages income"
                     imageURL="assets/img/icons/tanks/salary.png">
        </app-toggler>

        <app-toggler ngModel
                     name="property"
                     (ngModelChange)="toggle(priceList.PROPERTY_MONTHLY, $event)"
                     label="Property Tank"
                     description="Rental income"
                     imageURL="assets/img/icons/tanks/rental.png">
        </app-toggler>

        <app-toggler ngModel
                     name="sole"
                     (ngModelChange)="toggle(priceList.SOLE_MONTHLY, $event)"
                     label="Sole Tank"
                     description="Sole trader income"
                     imageURL="assets/img/icons/tanks/sole.png">
        </app-toggler>

        <app-toggler ngModel
                     name="holdings"
                     (ngModelChange)="toggle(priceList.HOLDING_MONTHLY, $event)"
                     label="Holdings Tank"
                     description="Shares, cryptos and other assets"
                     imageURL="assets/img/icons/tanks/dividends.png">
        </app-toggler>
      </app-toggler-group>
    </div>

    <div class="text-center">
      <div class="c-secondary mb-20">You can manage Tanks and trials from the My Account page at any time.</div>
      <button class="save-button secondary" [disabled]="!selectedPrices.length">
        Start using TaxTank
      </button>
      <i class="icon-help ic-secondary ml-10" matTooltip="Select at least one tank to continue"></i>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <div class="row align-h-right">
    <div class="col-12 col-sm-auto">
      <img class="logo" src="../../../../assets/img/icons/logo.svg" title="logo" alt="logo"/>
    </div>
  </div>
</div>
