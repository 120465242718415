import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
  Dictionary,
  SERVICE_PRODUCT_ROLES,
  ServiceProduct, ServiceProductCollection, ServiceProductIconsEnum,
  ServiceProductService, ServiceSubscription,
  ServiceSubscriptionCollection, UserRolesEnum
} from 'taxtank-core';
import { BaseComponent } from '../base/base.component';

/**
 * Component with user tanks indicators (Property, Work, e.t.c.)
 */
@Component({
  selector: 'app-tank-indicators',
  templateUrl: './tank-indicators.component.html',
  styleUrls: ['./tank-indicators.component.scss']
})
export class TankIndicatorsComponent extends BaseComponent implements OnInit {
  @Input() subscriptions: ServiceSubscription[];
  products: ServiceProductCollection;
  subscriptionsByProduct: Dictionary<ServiceSubscription>;
  tankRoles: UserRolesEnum[] = SERVICE_PRODUCT_ROLES;
  tankIcons: typeof ServiceProductIconsEnum = ServiceProductIconsEnum;

  constructor(
    private serviceProductService: ServiceProductService
  ) {
    super();
  }

  ngOnInit(): void {
    this.serviceProductService.get()
      .pipe(takeUntil(this.destroy$))
      .subscribe((products: ServiceProductCollection) => {
        this.products = products;
        this.subscriptionsByProduct = new ServiceSubscriptionCollection(this.subscriptions).groupByProduct(this.products);
      });
  }

  getTooltip(product: ServiceProduct, subscription: ServiceSubscription): string {
    let status: string;

    switch (true) {
      case subscription.isActive && !subscription.isTrial:
        status = 'Active';
        break;
      case subscription.isActive && subscription.isTrial:
        status = 'Trial';
        break;
      case !subscription.isActive:
        status = 'Inactive';
    }

    return `${product.title}: ${status}`;
  }
}
