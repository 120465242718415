<mat-form-field [class]="inputClass">
  <mat-label class="with-action">
    <div class="align-h-space-between">
      <span>{{ label }}</span>

      <!-- Add holding -->
      <button *ngIf="canManage && addButtonType === 'holding'"
              appStopPropagation
              type="button"
              (click)="openAddDialog(incomeSourceTypesEnum.HOLDING)"
              class="icon icon-plus link">
        Add Payer
      </button>

      <!-- Add work income -->
      <ng-container *ngIf="canManage && addButtonType === 'work'">
        <button appStopPropagation
                [matMenuTriggerFor]="menu"
                type="button"
                class="icon icon-plus link">
          Add Payer
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item
                  (click)="openAddDialog(incomeSourceTypesEnum.WORK)"
                  class="pv-10 ph-15 cursor-pointer">
            Add PAYG Income
          </button>

          <button mat-menu-item
                  (click)="openAddDialog(incomeSourceTypesEnum.OTHER)"
                  class="pv-10 ph-15 cursor-pointer">
            Add Other Income
          </button>
        </mat-menu>
      </ng-container>
    </div>
  </mat-label>

  <input #searchInput
         matInput
         [errorStateMatcher]="errorStateMatcher"
         [matAutocomplete]="auto"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         type="text"
         (input)="onInput($event.target.value)"
         (blur)="onTouched()"/>

  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="onSelect($event.option.value)"
                    [displayWith]="displayFn">

    <ng-container *ngIf="filteredOptions.length">
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{ option.name }}
      </mat-option>
    </ng-container>

    <mat-option *ngIf="!filteredOptions.length" [disabled]="true">
      No results found
    </mat-option>
  </mat-autocomplete>

  <div matSuffix>
    <ng-container [appPreloader]="[endpoints.INCOME_SOURCES_GET]"
                  [preloaderType]="preloaderTypes.ICON"
                  [preloaderSize]="20">
    </ng-container>
  </div>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
