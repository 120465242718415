<!--@TODO Artem replace isMobile checkings with global styles or use the same styles for all cases -->
<mat-form-field id="yearChooser"
                class="year-chooser compact no-error hover-background-primary"
                *ngIf="!isCollapsed">
  <mat-select [value]="user.financialYear" (selectionChange)="switch($event.value)" name="year-chooser-select">
    <mat-option *ngFor="let year of user.financialYears; let i = index" [value]="year">
      <ng-container *ngIf="year == currentYear; else yearLabel">
        Current FY
      </ng-container>
      <ng-template #yearLabel>
        FY: {{ year | financialYear: 'long' }}
      </ng-template>
    </mat-option>
  </mat-select>
  <i matPrefix class="icon icon-calendar {{isMobile ? 'ic-primary' : 'ic-white'}}"></i>
  <i matSuffix class="fa fa-chevron-down fs-default {{isMobile ? 'ic-primary' : 'ic-white'}}"></i>
</mat-form-field>

<div *ngIf="isCollapsed" class="year-label fw-bold c-secondary text-center">
  {{ user.financialYear | financialYear: 'long' }}
</div>
