import { Directive, ElementRef, HostListener, Input, forwardRef } from '@angular/core';
import { MAT_LEGACY_INPUT_VALUE_ACCESSOR as MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/legacy-input';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Directive for adding commas for the currency input fields, separating hundreds, thousands, etc.
 * @TODO Alex replace with currencyNumber input
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[inputCurrencyFormat]',
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: InputCurrencyFormatDirective
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCurrencyFormatDirective),
      multi: true,
    }
  ]
})
export class InputCurrencyFormatDirective {
  // Sometimes we should not allow the user to have negative numbers (for example, open balance, which means a loss, but cannot be negative)
  @Input() forbidNegativeNumbers = false;
  @Input() longDecimals: boolean;
  private currentValue: number | null;

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
  ) {
  }

  get value(): number | null {
    return this.currentValue;
  }

  @Input()
  set value(value: number | null) {
    this.currentValue = value;
    this.formatValue(value);
  }

  private formatValue(value: number | null): void {
    if (value !== null) {
      this.elementRef.nativeElement.value = this.numberWithCommas(value);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private numberWithCommas(value: number): string {
    if (!value && typeof value !== 'number') {
      return '';
    }

    const formattedValue: string = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (this.longDecimals) {
      const splitted: string[] = formattedValue.split('.');

      if (splitted.length === 2) {
        return `${splitted[0]}.${splitted[1].replace(/,/ig, '')}`;
      }
    }

    return formattedValue;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    // Don't format / write value if user write dot or minus
    if (value.endsWith('.') || value.endsWith('-')) {
      return;
    }

    if (!value) {
      this.writeValue(null);
      this.onChange(this.value);

      return;
    }

    // Remove all extra characters. Only numbers should remain, a single dot and a single minus (or remove all minus signs)
    this.writeValue(
      +value.replace(this.forbidNegativeNumbers ? /[^\d.]/g : /[^\d.-]/g, '')
        // Remove extra '.' symbols
        .replace(/(?:\..*)\./g, '.')
        // Remove extra '-' symbols
        .replace(/(?:-.*)-/g, '-')
    );
    this.onChange(this.value);
  }

  /**
   * Remove all extra characters when copying
   */
  @HostListener('copy', ['$event'])
  onKeyPress($event: ClipboardEvent) {
    $event.clipboardData.setData('text/plain', this.value.toString());
    $event.preventDefault();
  }

  @HostListener('blur')
  onBlur(): void {
    this.formatValue(this.value);
    this.onTouched();
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected onChange = (value: number): void => {
  };

  protected onTouched = (): void => {
  };
}
