<div mat-dialog-title>
  <div class="dialog-caption">Custom Personal Categories</div>
  <div class="dialog-subtitle">
    Add or edit new personal categories and customise your expense budget
  </div>
  <button mat-dialog-close class="icon-close"></button>
</div>

<div mat-dialog-content>
  <app-chart-accounts-form></app-chart-accounts-form>
</div>

<div mat-dialog-actions></div>
