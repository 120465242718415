import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Property, PropertyCollection, PropertyService } from 'taxtank-core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AbstractSelectComponent } from '@shared/component/form-controls/select/abstract-select/abstract-select.component';
import { Observable } from 'rxjs';
import { PropertiesAddFormDialogComponent } from '@client/shared/components/property/property-forms/property-add/properties-add-form-dialog/properties-add-form-dialog.component';
import { map } from 'rxjs/operators';

/**
 * Property select form control
 * @TODO Alex: create abstract select component with common logic for all select form controls (group, empty, etc.)
 */
@Component({
  selector: 'app-property-select',
  templateUrl: './property-select.component.html',
  styleUrls: ['./property-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // mat-checkbox styling
  encapsulation: ViewEncapsulation.None
})
export class PropertySelectComponent extends AbstractSelectComponent<Property> implements OnInit {
  @Input() emptyOptionLabel = 'All properties';
  @Input() emptyMessage = 'You haven\'t added any properties yet.';
  @Input() placeholder = 'Select property';
  @Input() label = 'Property';
  dialogComponent = PropertiesAddFormDialogComponent;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    protected dialog: MatDialog,
    private propertyService: PropertyService
  ) {
    super(ngControl, changeDetectorRef);
  }

  getOptions$(): Observable<Property[]> {
    return this.propertyService.get().pipe(
      map((properties: PropertyCollection) => properties.getActiveProperties().toArray())
    );
  }
}
