import { NgModule } from '@angular/core';
import { TogglerModule } from '@shared/component/form-controls/toggler/toggler.module';
import { VehicleClaimKmsSliderModule } from '@shared/component/form-controls/slider/vehicle-claim-kms-slider/vehicle-claim-kms-slider.module';
import { VehicleClaimWorkUsageSliderModule } from '@shared/component/form-controls/slider/vehicle-claim-work-usage-slider/vehicle-claim-work-usage-slider.module';

/**
 * Autocompletes
 */
import { BusinessActivityAutocompleteModule } from '@shared/component/form-controls/autocomplete/business-activity-autocomplete/business-activity-autocomplete.module';
import { ChartAccountsAutocompleteModule } from '@shared/component';
import { CountryAutocompleteModule } from '@shared/component/form-controls/autocomplete/country-autocomplete/country-autocomplete.module';
import { IncomeSourceSelectModule } from '@shared/component/form-controls/autocomplete/income-source-autocomplete/income-source-autocomplete.module';
import { OccupationAutocompleteModule } from '@shared/component/form-controls/autocomplete/occupation-autocomplete/occupation-autocomplete.module';
import { SoleContactSelectModule } from '@shared/component/form-controls/autocomplete/sole-contact-autocomplete/sole-contact-autocomplete.module';
import { HoldingTypeAutocompleteModule } from '@shared/component/form-controls/autocomplete/holding-type-autocomplete/holding-type-autocomplete.module';

/**
 * Selects
 */
import { BusinessSelectModule } from './select/business-select/business-select.module';
import { PropertySelectModule } from '@shared/component/form-controls/select/property-select/property-select.module';
import { SoleInvoiceTemplateSelectModule } from '@shared/component/form-controls/select/sole-invoice-template-select/sole-invoice-template-select.module';
import { SoleInvoiceTermSelectModule } from './select/sole-invoice-term-select/sole-invoice-term-select.module';
import { BankAccountSelectModule } from '@shared/component/form-controls/select/bank-account-select/bank-account-select.module';
import { HoldingTypeMultiselectModule } from '@shared/component/form-controls/select/holding-type-multiselect/holding-type-multiselect.module';
import { TankTypeSelectModule } from '@shared/component/form-controls/select/tank-type-select/tank-type-select.module';

/**
 * Inputs
 */
import { ClaimPercentInputModule } from '@shared/component/form-controls/input/claim-percent-input/claim-percent-input.module';
import { PasswordInputModule } from '@shared/component/form-controls/input/password-input/password-input.module';

/**
 * Files
 */
import { FileUploaderModule } from '@shared/component/form-controls/file/file-uploader/file-uploader.module';
import { FileModule } from '@shared/component/form-controls/file/file/file.module';
import { DragNDropModule } from './file/drag-n-drop/drag-n-drop.module';
import { DatepickerModule } from './input/datepicker/datepicker.module';
import { ConfirmPopupModule } from '@shared/component/form-controls/confirm-popup/confirm-popup.module';
import { PhoneInputModule } from '@shared/component/form-controls/form-input/phone-input/phone-input.module';
import { AddressInputModule } from '@shared/component/form-controls/form-input/address-input/address-input.module';
import {CurrencyInputModule} from '@shared/component/form-controls/input/currency-input/currency-input.module';


@NgModule({
  imports: [
    FileUploaderModule,
    ChartAccountsAutocompleteModule,
    ClaimPercentInputModule,
    DragNDropModule,
    FileModule,
    PropertySelectModule,
    TogglerModule,
    PasswordInputModule,
    BusinessSelectModule,
    VehicleClaimKmsSliderModule,
    VehicleClaimWorkUsageSliderModule,
    BusinessActivityAutocompleteModule,
    SoleInvoiceTermSelectModule,
    SoleContactSelectModule,
    SoleInvoiceTemplateSelectModule,
    CountryAutocompleteModule,
    IncomeSourceSelectModule,
    DatepickerModule,
    ConfirmPopupModule,
    PhoneInputModule,
    AddressInputModule,
    BankAccountSelectModule,
    HoldingTypeAutocompleteModule,
    HoldingTypeMultiselectModule,
    TankTypeSelectModule,
    CurrencyInputModule,
  ],
  exports: [
    FileUploaderModule,
    ChartAccountsAutocompleteModule,
    ClaimPercentInputModule,
    DragNDropModule,
    FileModule,
    PropertySelectModule,
    OccupationAutocompleteModule,
    TogglerModule,
    PasswordInputModule,
    BusinessSelectModule,
    VehicleClaimKmsSliderModule,
    VehicleClaimWorkUsageSliderModule,
    BusinessActivityAutocompleteModule,
    SoleInvoiceTermSelectModule,
    SoleContactSelectModule,
    SoleInvoiceTemplateSelectModule,
    CountryAutocompleteModule,
    IncomeSourceSelectModule,
    DatepickerModule,
    ConfirmPopupModule,
    PhoneInputModule,
    AddressInputModule,
    BankAccountSelectModule,
    HoldingTypeAutocompleteModule,
    HoldingTypeMultiselectModule,
    TankTypeSelectModule,
    CurrencyInputModule,
  ]
})
export class FormControlsModule { }
