import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Notification } from 'taxtank-core';

/**
 * Component with list of whole user notifications
 */
@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMessageComponent implements AfterViewChecked {
  @Input() notification: Notification;
  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewChecked(): void {
    // detect changes if profile-photo component was changed
    this.changeDetectorRef.markForCheck();
  }
}
