<div class="bg-light p-20">

  <div class="align-h-space-between">
    <div class="fw-bold">0%</div>
    <div class="fw-bold">{{workUsageLimit | number:'1.0-2'}}%</div>
  </div>

  <mat-slider [max]="workUsageLimit"
              [thumbLabel]="true"
              [displayWith]="formatPercentageLabel"
              [disabled]="disabled"
              [(ngModel)]="value"
              (change)="onSlide($event.value)">
  </mat-slider>
</div>
