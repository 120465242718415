<div class="calculator-container" (click)="$event.stopPropagation()">
  <form [formGroup]="form" *ngIf="formItems" class="transparent" appFormErrorScroll>
    <div class="align-h-space-between align-v-center mb-15">
      <h5 *ngIf="title" class="title">{{ title }}</h5>
      <button
        [disabled]="!form.valid"
        class="primary"
        (click)="submit(); menuTrigger.closeMenu()">Save</button>
    </div>

    <ng-container *ngFor="let item of formItems">
      <div class="row mb-15" *ngIf="!item.isDisabled">
        <mat-label class="col-6 fs-small fw-600">{{ item.label }}</mat-label>
        <!-- Selection field -->
        <mat-form-field *ngIf="item.isSelect" class="col-6 compact">
          <mat-select [formControl]="form.get(item.name)"
                      (selectionChange)="emitControlChanged(item.name, $event.value)">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Input field -->
        <mat-form-field *ngIf="!item.isSelect" class="col-6 compact">
          <input *ngIf="!item.isDisabled"
                 [formControl]="form.get(item.name)"
                 matInput type="number">
        </mat-form-field>
      </div>
    </ng-container>
  </form>
</div>
