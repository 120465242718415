import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { ChartAccountsService, VehicleClaim, VehicleClaimCollection, VehicleClaimService } from 'taxtank-core';
import { NgControl } from '@angular/forms';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';
import { map, takeUntil } from 'rxjs/operators';

/**
 * Component with vehicle claim KMs method functionality
 * @TODO Alex: think, maybe create abstract slider form control and move common logic there
 */
@Component({
  selector: 'app-vehicle-claim-kms-slider',
  templateUrl: './vehicle-claim-kms-slider.component.html',
  styleUrls: ['./vehicle-claim-kms-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleClaimKmsSliderComponent extends AbstractControlComponent<number> implements OnInit, OnChanges {
  /**
   * Current vehicle claim handled by form
   */
  @Input() vehicleClaim?: VehicleClaim;

  /**
   * List of all user's vehicle claims
   */
  private vehicleClaims: VehicleClaimCollection;

  /**
   * Maximum kilometers user available to claim
   */
  kmsLimit: number = VehicleClaim.totalKmsLimit;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private vehicleClaimService: VehicleClaimService,
    private chartAccountsService: ChartAccountsService
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.vehicleClaim) {
      this.updateKMSLimit();
    }
  }

  ngOnInit(): void {
    // can't get transactions and depreciations if claim does not exist (no claim id)
    if (!this.vehicleClaim) {
      return;
    }

    this.vehicleClaimService.get()
      .pipe(
        takeUntil(this.destroy$),
        map((claims: VehicleClaim[]) => new VehicleClaimCollection(claims))
      )
      .subscribe((claims) => {
        this.vehicleClaims = claims;
        this.updateKMSLimit();
      });
  }

  /**
   * Handle slider changes
   */
  onSlide(value: number) {
    // @TODO Alex: add 'this.value = value' when custom form controls refactoring finished
    this.onChange(value);
    this.updateKMSLimit();
  }

  /**
   * Calculate additional data for user
   */
  private updateKMSLimit(): void {
    // Get available kms limit (difference between total limit and other existing vehicle claims kms values)
    if (this.vehicleClaims) {
      this.kmsLimit = this.vehicleClaims.getKmsLimitForClaim(this.vehicleClaim);
    }

    this.changeDetectorRef.markForCheck();
  }
}
