import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User, UserService } from 'taxtank-core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class FirmGuard {
  constructor(protected router: Router, protected userService: UserService, private dialog: MatDialog) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.getFirst().pipe(map((user: User) => this.check(user, state)));
  }

  check(user: User, state: RouterStateSnapshot): boolean {
    if (user.isClient()) {
      this.router.navigateByUrl('/client');
    } else if (user.isOnboarding() && !state.url.includes('onboarding')) {
      this.router.navigateByUrl('/firm/onboarding');
      return false;
    }

    return true;
  }
}
