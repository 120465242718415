<mat-form-field *ngIf="mode === 'select'">
  <mat-label>Tank type</mat-label>
  <mat-select [disabled]="disabled"
              #matSelect
              [errorStateMatcher]="errorStateMatcher"
              [placeholder]="placeholder"
              [(ngModel)]="value"
              (selectionChange)="onChange($event.value)"
              (blur)="onTouched()">
    <mat-option *ngIf="hasEmptyOption" [value]="false">{{emptyOptionLabel}}</mat-option>
    <mat-option *ngIf="user.hasRoles(userRoles.WORK_TANK)" [value]="tankTypes.WORK">Work</mat-option>
    <mat-option *ngIf="user.hasRoles(userRoles.PROPERTY_TANK)" [value]="tankTypes.PROPERTY">Property</mat-option>
    <mat-option *ngIf="user.hasRoles(userRoles.SOLE_TANK)" [value]="tankTypes.SOLE">Sole</mat-option>
    <mat-option *ngIf="!hideHoldingTank && user.hasRoles(userRoles.HOLDING_TANK)" [value]="tankTypes.HOLDING">Holding</mat-option>
  </mat-select>
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>

<mat-radio-group *ngIf="mode === 'radioIcons'"
                 class="icon-buttons"
                 [(ngModel)]="value"
                 [errorStateMatcher]="errorStateMatcher"
                 (change)="onChange($event.value)">
  <mat-radio-button *ngIf="user.hasRoles(userRoles.WORK_TANK)"
                    name="radio-button-work"
                    matTooltip="Work Tank"
                    [value]="tankTypes.WORK">
    <i class="icon icon-portfolio"></i>
  </mat-radio-button>

  <mat-radio-button *ngIf="user.hasRoles(userRoles.PROPERTY_TANK)"
                    name="radio-button-property"
                    matTooltip="Property Tank"
                    [value]="tankTypes.PROPERTY">
    <i class="icon icon-property"></i>
  </mat-radio-button>

  <mat-radio-button *ngIf="user.hasRoles(userRoles.SOLE_TANK)"
                    name="radio-button-sole"
                    matTooltip="Sole Tank"
                    [value]="tankTypes.SOLE">
    <i class="icon icon-sole-tank"></i>
  </mat-radio-button>
  <mat-radio-button *ngIf="!hideHoldingTank && user.hasRoles(userRoles.HOLDING_TANK)"
                    name="radio-button-sole"
                    matTooltip="Holdings Tank"
                    [value]="tankTypes.HOLDING">
    <i class="icon icon-holding-tank"></i>
  </mat-radio-button>

  <mat-radio-button [value]="tankTypes.PERSONAL"
                    name="radio-button-personal"
                    matTooltip="Personal">
    <i class="icon icon-my-account"></i>
  </mat-radio-button>
</mat-radio-group>

<mat-radio-group *ngIf="mode === 'radioLabels'"
                 (change)="onChange($event.value)"
                 [(ngModel)]="value"
                 class="primary">
  <mat-radio-button *ngIf="hasEmptyOption"
                    [value]="false">
    {{emptyOptionLabel}}
  </mat-radio-button>
  <mat-radio-button *ngIf="user.hasRoles(userRoles.WORK_TANK) && types.includes(tankTypeList.WORK)"
                    [value]="tankTypes.WORK">
    Work Tank
  </mat-radio-button>
  <mat-radio-button *ngIf="user.hasRoles(userRoles.PROPERTY_TANK) && types.includes(tankTypeList.PROPERTY)"
                    [value]="tankTypes.PROPERTY">
    Property Tank
  </mat-radio-button>
  <mat-radio-button *ngIf="user.hasRoles(userRoles.SOLE_TANK) && types.includes(tankTypeList.SOLE)"
                    [value]="tankTypes.SOLE">
    Sole Tank
  </mat-radio-button>
  <mat-radio-button *ngIf="!hideHoldingTank && user.hasRoles(userRoles.SOLE_TANK) && types.includes(tankTypeList.HOLDING)"
                    [value]="tankTypes.HOLDING">
    Holding Tank
  </mat-radio-button>
</mat-radio-group>
