<ng-container [appPreloader]="[endpoints.INCOME_SOURCE_TYPES_GET,
                               endpoints.INCOME_SOURCES_POST,
                               endpoints.INCOME_SOURCES_PUT,
                               endpoints.USER_PUT]"
              [preloaderType]="preloaderTypes.OVERLAY">
  <div *ngIf="form" class="ph-20">
    <div class="row g-0 m-15">
      <div class="col-12 col-lg-6">
        <div class="title align-v-center">
          <i class="icon icon-payed is-big ic-secondary"></i>
          <div class="ml-10">
            <h5>Salary and Wages</h5>
            <div class="mv-5">Let us know your PAYG Income details</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">

        <mat-radio-group id="onBoardingSalaryResidentSelect" [(ngModel)]="isAustralianResident"
                         (ngModelChange)="onResidencyChanged()">
          <mat-radio-button [value]="true">Resident</mat-radio-button>
          <mat-radio-button [value]="false">Non-Resident</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="tabs">
      <div *ngIf="!incomeSource.id"
           id="onBoardingSalaryAdd"
           class="tab-add"
           (click)="addFormGroup()">
        <i class="fa fa-plus-circle"></i>
        Add Salary
      </div>

      <div class="tab d-flex align-v-center"
           *ngFor="let group of form.controls; let i = index"
           [class.active]="i === displayedFormIndex"
           (click)="selectForm(i)">
        <span [class.c-error]="group.invalid && group.touched">
          {{group.get('name').value || 'Employer ' + (i + 1)}}
        </span>
        <button *ngIf="form.controls.length > 1"
                appStopPropagation
                (click)="removeForm(i)"
                class="icon-close">
        </button>
      </div>
    </div>

    <form [formGroup]="form" appFormErrorScroll>
      <ng-container *ngFor="let group of form.controls; let i = index">
        <ng-container [formGroup]="group" *ngIf="i === displayedFormIndex">
          <div class="row">
            <ng-container formArrayName="salaryForecasts">
              <ng-container *ngFor="let salaryControl of group.get('salaryForecasts')['controls']; let i = index">
                <ng-container [formGroup]="salaryControl">
                  <div class="col-12 col-md-6">
                    <div class="salary-forecast">
                      <ng-container [appPreloader]="[endpoints.TAX_CALCULATION_POST]" [preloaderType]="preloaderTypes.OVERLAY">
                        <h6>Gross Amount</h6>
                        <div class="salary-forecast-value">
                          {{ salaryControl.get('grossAmount').value | customCurrency }}
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="salary-forecast">
                      <ng-container [appPreloader]="[endpoints.TAX_CALCULATION_POST]" [preloaderType]="preloaderTypes.OVERLAY">
                        <h6>Net Amount</h6>
                        <div class="salary-forecast-value" *ngIf="!isNetAmountEdit">
                          {{salaryControl.get('netPay').value | customCurrency}}
                        </div>
                        <mat-form-field class="compact" *ngIf="isNetAmountEdit">
                          <input matInput type="number" formControlName="netPay">
                          <mat-error class="mt-20" *ngIf="salaryControl.get('netPay').invalid">
                            <app-validation-error [errors]="salaryControl.get('netPay').errors"></app-validation-error>
                          </mat-error>
                        </mat-form-field>
                        <button *ngIf="!isNetAmountEdit" (click)="startEditNetAmount()"
                           class="salary-forecast-edit-icon icon icon-edit">
                        </button>
                        <button *ngIf="isNetAmountEdit" (click)="finishEditNetAmount(salaryControl)"
                           class="salary-forecast-edit-icon icon icon-check">
                        </button>
                      </ng-container>
                    </div>

                    <app-preloader [size]="30" *ngIf="isTaxCalculationLoading"></app-preloader>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>

            <div class="col-12 col-md-6">
              <mat-form-field id="onBoardingSalaryEmployerAdd">
                <mat-label>Employer Name</mat-label>
                <input matInput formControlName="name" placeholder="Enter Employer Name" required>

                <mat-error *ngIf="group.get('name').invalid">
                  <app-validation-error [errors]="group.get('name').errors"></app-validation-error>
                </mat-error>
              </mat-form-field>
            </div>

            <ng-container formArrayName="salaryForecasts">
              <ng-container *ngFor="let salaryControl of group.get('salaryForecasts')['controls']; let i = index">
                <ng-container [formGroup]="salaryControl">
                  <div class="col-12 col-md-6">
                    <app-currency-input min="0"
                                        inputId="onBoardingSalaryAnnualInput"
                                        formControlName="paygIncome"
                                        label="Annual Salary"
                                        placeholder="Enter Annual Amount">
                    </app-currency-input>
                  </div>

                  <div class="col-12 col-md-6">

                    <mat-form-field id="onBoardingSalaryPayFrequencySelect">
                      <mat-label>Pay Frequency</mat-label>
                      <mat-select formControlName="frequency" placeholder="Select Pay Frequency" required>
                        <mat-option [value]="paymentFrequencyTypes.WEEKLY">Weekly</mat-option>
                        <mat-option [value]="paymentFrequencyTypes.MONTHLY">Monthly</mat-option>
                        <mat-option [value]="paymentFrequencyTypes.FORTNIGHTLY">Fortnightly</mat-option>
                      </mat-select>

                      <mat-error *ngIf="salaryControl.get('frequency').invalid">
                        <app-validation-error [errors]="salaryControl.get('frequency').errors"></app-validation-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngIf="isAustralianResident" class="col-12 col-md-6">
                    <mat-form-field id="onBoardingSalaryThresholdSelect">
                      <mat-label>Tax-Free Threshold</mat-label>
                      <mat-select formControlName="isTaxFree" placeholder="Select Threshold" required>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                      </mat-select>

                      <mat-error *ngIf="salaryControl.get('isTaxFree').invalid">
                        <app-validation-error [errors]="salaryControl.get('isTaxFree').errors"></app-validation-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>

            <div class="col-12 col-md-6">
              <app-datepicker id="onBoardingSalaryJobStartSelect"
                              formControlName="dateFrom"
                              placeholder="Start Date"
                              label="Job Start Date">
              </app-datepicker>
            </div>

            <div class="col-12 col-md-6 align-v-center">
              <mat-checkbox formControlName="isCurrent" matTooltip="Is this job current?">Current</mat-checkbox>
            </div>

            <ng-container *ngIf="!group.get('isCurrent').value">
              <div class="col-12 col-md-6">
                <app-datepicker formControlName="dateTo"
                                placeholder="End Date"
                                label="Job End Date">
                </app-datepicker>
              </div>

              <div class="col-12 col-md-6">
                <mat-form-field>
                  <mat-label>Reason (*optional)</mat-label>
                  <input [maxlength]="100" matInput formControlName="closeReason" />

                  <mat-error *ngIf="group.get('closeReason').invalid">
                    <app-validation-error [errors]="form.get('closeReason').errors"></app-validation-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
</ng-container>
