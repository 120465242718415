<div class="document p-10 mb-10 align-v-center align-h-space-between bg-white shadow border-radius">
  <div class="align-v-center">
    <i class="icon {{getDocIcon(file)}} ic-primary mr-10 is-big"></i>
    <div>
      <div class="c-secondary fs-default mb-5">{{file.originalName}}</div>
      <div class="c-text fs-small">{{file.createdAt | date}}</div>
    </div>
  </div>

  <div class="align-v-center">
    <div class="fs-big c-secondary mr-10">{{file.size | fileSizeConvert}}</div>
    <i class="icon icon-button icon-download ic-text cursor-pointer" [appDownload]="file"></i>
  </div>
</div>
