import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CustomCurrencyPipe } from '@shared/pipe/custom-currency/custom-currency.pipe';
import { BaseChartComponent } from '@shared/component/charts/base-chart/base-chart.component';
import { COLORS, PALETTE_PRIMARY } from '@shared/globals/colors';
import { ViewPortService } from '@shared/services/view-port.service';

// save component to variable to use it inside anonymous formatter functions
let self: PieChartComponent;

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChartComponent extends BaseChartComponent implements OnChanges {
  // field name for column value
  @Input() bindValue: string;
  @Input() bindSeriesData = '';
  @Input() text?: string;
  // chart height
  @Input() height: number = this.viewPortService.isMobile ? 300 : 200;

  constructor(
    public customCurrencyPipe: CustomCurrencyPipe,
    public viewPortService: ViewPortService
  ) {
    super(customCurrencyPipe);
    self = this;
  }

  ngOnChanges(): void {
    this.initChart();
  }

  initChart(): void {
    if (!this.data.length) {
      return;
    }

    this.buildData();
    // set options declared in base class
    this.setDefaultOptions();
    // set options s
    this.setOptions();
    // hack for dynamic containers
    setTimeout(() => {
      this.showChart();
    });
  }

  buildData(): void {
    this.chartData = this.data.map((item: any) => {
      if (this.bindSeriesData) {
        let value = 0;
        item[this.bindSeriesData].forEach((serie: any) => {
          value = value + Math.abs(serie[this.bindValue]);
        });
        return {
          name: item[this.bindSeriesName],
          value: value
        };
      }
      return {
        name: item[this.bindSeriesName],
        value: Math.abs(item[this.bindValue])
      };

    });
  }

  setOptions(): void {
    // set chart type
    this.chartOptions.chart.type = 'pie';

    // show/hide Y axis
    this.chartOptions.yAxis.visible = false;

    // show legend
    this.chartOptions.legend = {
      enabled: true,
      align: 'left',
      layout: 'vertical',
      verticalAlign: 'middle',
      itemMarginBottom: 20,
      width: 150
    };

    // set chart colors
    this.chartOptions.colors = PALETTE_PRIMARY;

    // set chart height
    this.chartOptions.chart.height = this.height;

    // set tooltip template
    this.chartOptions.tooltip.formatter = function (): string {
      return `<span style="font-size: 14px; color: ${this.point.color}">●</span>
                  <span style="font-size: 14px;">
                    ${this.point.name}: ${self.isCurrency ? self.customCurrencyPipe.transform(this.point.y, true) : this.point.y}
                  </span>`;
    };

    this.chartOptions.title = {
      align: 'center',
      widthAdjust: 0,
      verticalAlign: 'middle',
      floating: true,
      useHTML: true,
      text: this.text,
    };

    this.chartOptions.series = [{
      size: 130,
      innerSize: 110,
      showInLegend: true,
      borderWidth: 0,
      data: this.chartData.map((d: any, index: number) => ({
        name: d.name,
        y: d.value,
        dataLabels: {
          style: {
            textOutline: `1px ${Highcharts.color(PALETTE_PRIMARY[index])}`
          }
        }
      })),
      dataLabels: {
        formatter: function (): string {
          return this.y;
        },
        enabled: true,
        color: this.viewPortService.isMobile ? COLORS.secondary : COLORS.white,
        distance: this.viewPortService.isMobile ? 25 : -25,
        style: {
          fontSize: this.viewPortService.isMobile ? '12px' : '10px',
          fontWeight: 'normal'
        }
      }
    }];

    this.chartOptions.responsive = {
      rules: [{
        condition: {
          callback: () => this.viewPortService.isMobile
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          },
          chart: {
            height: 'auto'
          }
        }
      }]
    };
  }

  showChart(): void {
    Highcharts.chart(this.containerId, this.chartOptions);
  }
}
