import { ContentChildren, Directive, ElementRef, HostListener, QueryList } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';

/**
 * Directive to scroll view to invalid formControl.
 * Put it on element with formGroup directive.
 * Directive listens to 'ngSubmit' event
 */
@Directive({
  selector: '[appFormErrorScroll]'
})
export class FormErrorScrollDirective {
  @ContentChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  parentExpansionPanelInstance: MatExpansionPanel;

  constructor(
    private el: ElementRef,
    private formGroupDir: FormGroupDirective,
  ) {
  }

  @HostListener('ngSubmit') onSubmit() {
    if (this.formGroupDir.control.invalid) {
      this.setParentExpansionPanelInstance();

      // if invalid control is in MatExpansionPanel - open it and scroll
      if (this.parentExpansionPanelInstance && !this.parentExpansionPanelInstance.expanded ) {
        this.parentExpansionPanelInstance.open();
        this.parentExpansionPanelInstance.afterExpand.subscribe(() => {
          this.scrollToInvalidControl();
        });
      } else {
        this.scrollToInvalidControl();
      }
    }
  }

  scrollToInvalidControl(): void {
    this.el.nativeElement.querySelector('.ng-invalid').scrollIntoView({behavior: 'smooth'});
  }

  /**
   * Sometimes invalid control can be in a MatExpansionPanel. Therefore, we need to find it to open
   */
  setParentExpansionPanelInstance(): void {
    const parentExpansionPanelElement: Element = this.el.nativeElement.querySelector('.ng-invalid').closest('.mat-expansion-panel-content');
    if (!parentExpansionPanelElement) {
      return;
    }

    this.parentExpansionPanelInstance = this.expansionPanels.find((panel: MatExpansionPanel) => panel.id === parentExpansionPanelElement.id);
  }
}
