import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BaseChartComponent } from './base-chart/base-chart.component';

@NgModule({
  declarations: [
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
    ProgressSpinnerComponent,
    BaseChartComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
    ProgressSpinnerComponent
  ],
})
export class ChartsModule { }
