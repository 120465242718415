<header mat-dialog-title>
  <button mat-dialog-close class="icon-close"></button>
</header>

<div mat-dialog-content>
  <div class="content-wrapper">
    <h1>Enjoy your 14 day free trial</h1>

    <div class="description">
      You're on your way to becoming....
      Tax Confident!
      <br><br>
      We are very excited that you have registered for an account with TaxTank and we hope you enjoy using the software
      over the next 14 days during your free trial.
    </div>

    <div class="button-area">
      <button class="primary" (click)="dialogRef.close()">Start using TaxTank</button>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <div class="logo-container">
    <img src="assets/img/icons/logo.svg" title="logo" alt="logo"/>
  </div>
</div>
