import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import {
  CHART_ACCOUNTS_CATEGORIES, ChartAccounts,
  Collection,
  TankTypeEnum,
  VehicleClaim, VehicleClaimService
} from 'taxtank-core';
import { combineLatest } from 'rxjs';
import { AbstractControlComponent } from '../../abstract-control/abstract-control.component';

/**
 * Claim percent input form field.
 * Need to work with chart accounts and reacts to their changes
 */
@Component({
  selector: 'app-claim-percent-input',
  templateUrl: './claim-percent-input.component.html',
  styleUrls: ['./claim-percent-input.component.scss']
})
export class ClaimPercentInputComponent extends AbstractControlComponent<number> implements OnInit {
  @Input() control: AbstractControl;
  @Input() isCompact: boolean;
  @Input() label = 'Claim percent';
  @Input() placeholder = 'Claim percent';
  vehicleClaims: Collection<VehicleClaim>;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private vehicleClaimService: VehicleClaimService,
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    // handle vehicle transaction claim percent
    // @TODO Alex: hack - refactor: move logic to forms, Move component to VALUE_ACCESSOR or remove at all
    combineLatest([
      this.vehicleClaimService.get(),
      this.ngControl.control.parent.get('chartAccounts').valueChanges
    ]).subscribe(([vehicleClaims, chartAccounts]: [VehicleClaim[], ChartAccounts]) => {
      this.vehicleClaims = new Collection<VehicleClaim>(vehicleClaims);

      if (!(chartAccounts instanceof ChartAccounts) || !chartAccounts.isVehicleExpense() || chartAccounts.isVehicleLoanPrinciple()) {
        return;
      }

      let tankType: TankTypeEnum;
      if (CHART_ACCOUNTS_CATEGORIES.work.includes(chartAccounts.category)) {
        tankType = TankTypeEnum.WORK;
      } else if (CHART_ACCOUNTS_CATEGORIES.sole.includes(chartAccounts.category)) {
        tankType = TankTypeEnum.SOLE;
      }

      this.value = this.vehicleClaims.filterBy('tankType', tankType).first?.workUsage || 0;
      this.onChange(this.value);
    });
  }
}
