import { NgModule } from '@angular/core';
import { HeaderModule } from '@shared/component/layout/header/header.module';
import { FooterModule } from '@shared/component/layout/footer/footer.module';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { SidenavToggleButtonModule } from '@shared/component/layout/sidenav-toggle-button/sidenav-toggle-button.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PipeModule } from '@shared/pipe/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    MatTooltipModule,
    SidenavToggleButtonModule,
    DirectivesModule,
    MatExpansionModule,
    PipeModule
  ],
  exports: [
    HeaderModule,
    FooterModule,
    LayoutComponent,
    SidenavComponent
  ],
  declarations: [
    LayoutComponent,
    MenuComponent,
    SidenavComponent
  ]
})
export class LayoutModule { }
