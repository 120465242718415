import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { InputNumber } from 'primeng/inputnumber';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';
import { Validators } from '@angular/forms';

/**
 * https://primeng.org/inputnumber
 * extended prime component to add expense logic
 */
@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
})
export class CurrencyInputComponent extends AbstractControlComponent<number> implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(InputNumber) private inputNumber: InputNumber;
  @Output() onInput: EventEmitter<number> = new EventEmitter<number>();
  @Input() inputId = '';
  @Input() inputStyleClass = '';
  @Input() isDebit: boolean;
  @Input() prefix = '';
  @Input() label = 'Amount';
  @Input() tooltip: string;
  @Input() placeholder = 'Enter amount';
  @Input() min: number = null;
  @Input() max: number = null;
  @Input() maxFractionDigits = 2;
  @Input() required = true;
  @Input() errors = this.ngControl.errors;
  isRequired: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isChangeSignRequired(this.ngControl.value)) {
      return;
    }

    // change control value to negative if it's positive and input isExpense changed (not first time)
    this.ngControl.control.setValue(-this.ngControl.value);
  }

  ngOnInit() {
    this.isRequired = this.ngControl.control.hasValidator(Validators.required);
  }

  ngAfterViewInit() {
    // extend validateValue method to add changeSign logic
    const validateValue = this.inputNumber.validateValue;
    this.inputNumber.validateValue = (value: number | string) => {
      const result = validateValue.call(this.inputNumber, value);
      if (!this.isChangeSignRequired(this.ngControl.value)) {
        return result;
      }

      return -result;
    };
  }

  private isChangeSignRequired(value: number): boolean {
    return this.isDebit !== undefined && ((this.isDebit && value > 0) || (!this.isDebit && value < 0));
  }

  protected readonly oninput = oninput;
}
