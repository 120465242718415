<div class="p-inputnumber-wrapper mat-form-field" [class]="inputClass" [class.disabled]="ngControl.disabled">
  <p-inputNumber (onInput)="onInput.emit($event.value)"
                 [inputId]="inputId"
                 [formControl]="ngControl.control"
                 [placeholder]="placeholder"
                 [min]="min"
                 [max]="max"
                 [inputStyleClass]="inputStyleClass"
                 [required]="isRequired"
                 class="w-100"
                 mode="currency"
                 currency="AUD"
                 [prefix]="prefix"
                 [maxFractionDigits]="maxFractionDigits"
                 locale="en-AU">
  </p-inputNumber>
  <label [class.required]="isRequired">
    {{ label }}
    <i *ngIf="tooltip" class="icon icon-info ic-secondary ml-5" [matTooltip]="tooltip"></i>
  </label>

  <div class="mat-form-field-subscript-wrapper m-0">
    <mat-error>
      <app-validation-error *ngIf="ngControl.touched" [errors]="ngControl.errors"></app-validation-error>
    </mat-error>
  </div>
</div>
