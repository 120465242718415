import { PreloaderTypeEnum } from '@shared/component/preloader/preloader-type.enum';
import { ENDPOINTS, Endpoint, FinancialYear, UserRolesEnum } from 'taxtank-core';
import { Subject } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';

/**
 * Abstract base component with common functionality for all components
 */
// Empty Component decorator is required for Angular lifecycle hooks
// Compiler error: Class is using Angular features but is not decorated. Please add an explicit Angular decorator.
@Component({template: ''})
export abstract class BaseComponent implements OnDestroy {
  endpoints: {[key: string]: Endpoint} = ENDPOINTS;
  userRoles: typeof UserRolesEnum = UserRolesEnum;
  preloaderTypes: typeof PreloaderTypeEnum = PreloaderTypeEnum;
  currentYear: number = new FinancialYear().year;
  /**
   * Subject keep component's subscriptions and unsubscribe when component destroyed
   * https://stackoverflow.com/questions/47152752/angular-subscribe-unsubscribe-best-practices
   * @TODO Alex: think about smarter universal solution (implement in services or something like this)
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    // Unsubscribe from whatever used takeUntil(destroy$)
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
