import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  Bank, BankConnection,
  BankConnectionService, BankService,
  Collection,
} from 'taxtank-core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/component/base/base.component';
import { Observable, combineLatest } from 'rxjs';

/**
 * Component with list of available banks
 */
@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BanksComponent extends BaseComponent implements OnInit, OnInit {
  @Output() selected: EventEmitter<BankConnection> = new EventEmitter();
  /**
  * Show preloader after choosing a bank
  */
  hasSelected: boolean;

  /**
   * List of user's bank connections
   */
  connections: Collection<BankConnection>;

  /**
   * List of all bank allowed to connect
   */
  banks: Bank[];

  constructor(private bankConnectionService: BankConnectionService, private bankService: BankService) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.bankConnectionService.get() as Observable<Collection<BankConnection>>,
      this.bankService.get() as Observable<Collection<Bank>>
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([connections, banks]): void => {
        this.banks = banks.diff(connections.mapBy('bank')).sortBy('isPopular').toArray();
      });
  }

  select(connection: BankConnection): void {
    this.selected.emit(connection);
  }
}
