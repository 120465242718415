import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from '@shared/component/layout/menu/menu-item';
import { VideoGalleryDialogComponent } from '@shared/component/video-gallery-dialog/video-gallery-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User, UserService } from 'taxtank-core';

/**
 * Component with header menu
 */
@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
  @ViewChild('headerMenu') menuWrapper: ElementRef;
  @Input() items: MenuItem[];
  user: User;

  constructor(private dialog: MatDialog, private userService: UserService) {}

  ngOnInit() {
    this.user = this.userService.getCacheFirst();
  }

  openCVideoPlayerDialogComponent(): void {
    this.dialog.open(VideoGalleryDialogComponent, {
      panelClass: ['dialog-medium', 'mobile-full-screen']
    });
  }
}
