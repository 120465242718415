import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Notification, ServiceNotificationService, User, UserService} from 'taxtank-core';

// amount of delay in milliseconds after which notifications will be read
const NOTIFICATIONS_READ_DELAY = 1000;

/**
 * Component with main notifications functionality
 */
@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent implements OnInit, OnChanges, AfterViewChecked {
  @Output() received: EventEmitter<void> = new EventEmitter<void>();
  // limit of notifications to show
  @Input() notificationsLimit: number;
  // is notifications related to popup element
  @Input() isPopup = false;
  // flag that shows if notifications should be read or not
  @Input() shouldBeRead = true;
  // we need list of notifications as Observable to get unreadNotificationsCount$ value
  notifications$: Observable<Notification[]>;
  // amount of unread notifications (we need to store it as Observable for appNotifications directive)
  unreadNotificationsCount$: Observable<number>;
  unreadNotifications: Notification[];
  user: User;

  constructor(
    private serviceNotificationService: ServiceNotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService
  ) {
  }

  ngOnChanges(): void {
    if (this.shouldBeRead) {
      this.read();
    }
  }

  ngOnInit(): void {
    this.user = this.userService.getCacheFirst();
    this.setNotifications();
    this.setUnreadNotificationsCount();
  }

  ngAfterViewChecked(): void {
    // detect changes if child components was changed
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Set all notifications
   */
  private setNotifications(): void {
    this.notifications$ =
      this.serviceNotificationService.get().pipe(
        map((notifications: Notification[]) => {
          notifications = notifications.slice(0, this.notificationsLimit);

          // We will not open the list of notifications on initialization, but only when we receive a new notification
          // @TODO hack: we shouldn't get here more than once when excited
          if (this.unreadNotifications && this.unreadNotifications.length && (this.unreadNotifications.length !== notifications.length)) {
            this.received.emit();
          }

          this.unreadNotifications = notifications
            .filter((notification: Notification): boolean => !notification.isRead);

          // read notifications if unread notifications are exists and isRead flag is 'true'
          if (this.unreadNotifications.length && this.shouldBeRead) {
            this.read();
          }

          return notifications;
        }),
      );
  }

  /**
   * Set unread notifications
   */
  private setUnreadNotificationsCount(): void {
    this.unreadNotificationsCount$ = this.notifications$.pipe(
      map((notifications: Notification[]) => notifications
        .filter((notification: Notification): boolean => !notification.isRead).length));
  }

  /**
   * Read notifications after little delay
   */
  read(): void {
    setTimeout(() => {
      this.unreadNotifications.forEach((notification: Notification) => {
        notification.isRead = true;
        this.serviceNotificationService.update(notification).subscribe(() => {
          this.changeDetectorRef.markForCheck();
        });
      });
    }, NOTIFICATIONS_READ_DELAY);
  }
}
