<div class="subscription-item">
  <div class="row">
    <div class="col-12 col-md-3">
    <div class="col-12 col-md-3">
      <!--mat-checkbox add '-input' suffix to the inner input id-->
      <label [for]="product.role + '-input'"
             class="{{ product.role }} subscription-item-title align-v-center pointer"
             [class.disabled]="isDisabled">
        <mat-checkbox [(ngModel)]="isSelected"
                      [disabled]="isDisabled"
                      (change)="toggle()"
                      (click)="$event.stopPropagation()"
                      class="round"
                      [id]="product.role">
        </mat-checkbox>

        <h6>{{ product.title }}</h6>
      </label>
    </div>
    <div class="col-12 col-md-9 p-relative">
      <div class="overlay" *ngIf="!isSelected"></div>
      <div class="row pv-20">
        <div class="col-12 col-md-9">
          <span>{{ product.description }}</span>
        </div>

        <div class="col-12 col-md-3">
          <div class="mr-20 text-right c-secondary fs-big">${{ item.total }}/{{ currentSubscription.frequency }}</div>
        </div>
        <div class="col-12">
          <div class="row mt-10" *ngIf="product.isCountable">
            <div class="col-12 col-md-8">
              <div><b>Add items</b><br>{{ item.price.amount | customCurrency }} per additional items</div>
            </div>
            <div class="col-12 col-md-4">
              <mat-form-field class="compact no-error properties-select pr-20">
                <mat-select [(ngModel)]="item.quantity">
                  <ng-container *ngFor="let i of quantityRange">
                    <!-- Disable if it less than min or equal to number of properties in subscription -->
                    <mat-option [disabled]="(i < min)"
                                [value]="i">
                      <div class="align-h-space-between">
                        <span>{{ i }} properties</span>
                      </div>
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
