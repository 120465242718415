import { Component, Input, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { ClientMovementCollection, ClientMovementService, User, UserSwitcherService, sort } from 'taxtank-core';
import { ViewPortService } from '@shared/services/view-port.service';

@Component({
  selector: 'app-user-switcher',
  templateUrl: './user-switcher.component.html',
  styleUrls: ['./user-switcher.component.scss']
})
export class UserSwitcherComponent implements OnInit {
  // @TODO waiting for clients api
  @Input() isSwitched: boolean;
  clients: User[];
  filteredClients: User[];
  currentUser: User;

  constructor(
    private clientMovementService: ClientMovementService,
    private userSwitcherService: UserSwitcherService,
    public viewPortService: ViewPortService
  ) {
  }

  ngOnInit(): void {
    this.clientMovementService.getActive().subscribe((clientMovements: ClientMovementCollection) => {
      // @TODO move to collection
      this.clients = this.filteredClients = clientMovements.clients.items;
      sort(this.clients, 'fullName', false);
    });
  }

  switch($event: MatAutocompleteSelectedEvent): void {
    this.currentUser = $event.option.value;
    this.userSwitcherService.set(this.currentUser.email);
  }

  filterUsers(value: string) {
    this.filteredClients = cloneDeep(this.clients);
    this.filteredClients = this.filteredClients
      .filter((client: User) => client.fullName.toLowerCase().includes(value.toLowerCase()));
  }
}
