<header mat-dialog-title>
  <h2 class="dialog-caption">{{soleContact ? 'Edit' : 'Add'}} contact</h2>
  <button mat-dialog-close class="icon-close"></button>
</header>

<div mat-dialog-content>
  <app-sole-contact-form #soleContactFormComponent
                         [contact]="soleContact"
                         (saved)="dialogRef.close()">
  </app-sole-contact-form>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button mat-dialog-close class="basic w-100">Cancel</button>
    </div>
    <div class="col-6 col-sm-auto">
      <button [appFormSubmit]="soleContactFormComponent" class="primary w-100">Save</button>
    </div>
  </div>
</div>
