import { AfterViewInit, Component, Input, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NavigationLink } from './navigation-link';
import { MatLegacyTabLink as MatTabLink } from '@angular/material/legacy-tabs';
import { SWIPER_OPTIONS } from '@shared/component/navigation/swiper-options';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

declare type ColorType = 'primary' | 'secondary' | 'light' | 'transparent';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class NavigationComponent implements AfterViewInit {
  @ViewChildren(MatTabLink) tabLinks: QueryList<MatTabLink>;
  @ViewChild('swiper') swiper: SwiperComponent;
  @Input() links: NavigationLink[];
  // full width nav bar
  @Input() isBlock: boolean;
  // style tabs: primary/secondary/light
  @Input() color: ColorType;
  carouselConfig: SwiperOptions = SWIPER_OPTIONS;

  ngAfterViewInit(): void {
    // @Todo Mikhail refactor with RouterLinkActive 'isActiveChange' event when app will be updated to 14+ ver.
    // we need a timeout to focus active element after view rendered
    setTimeout(() => {
      this.tabLinks.forEach((link: MatTabLink, index: number) => {
        if (link.active) {
          this.slideToActiveTab(index);
        }
      });
    });
  }

  slideToActiveTab(index: number) {
    this.swiper.swiperRef.slideTo(index);
  }
}
