<ng-container [appPreloader]="[
                endpoints.SOLE_INVOICE_TEMPLATES_POST,
                endpoints.SOLE_INVOICE_TEMPLATES_PUT
              ]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()" appFormErrorScroll>
  <div class="row">
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>Name</mat-label>

        <input matInput formControlName="name" placeholder="Enter template name">

        <mat-error>
          <app-validation-error [errors]="form.get('name').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-6">
      <app-sole-invoice-term-select formControlName="term"></app-sole-invoice-term-select>
    </div>

    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>
          Tax type
          <i class="icon-info" matTooltip="Select 'No tax' if you are not registered for GST"></i>
        </mat-label>

        <mat-select formControlName="taxType">
          <mat-option *ngFor="let type of taxTypes | ekeyvalue" [value]="+type.key">
            {{ type.value | translate }}
          </mat-option>
        </mat-select>

        <mat-error>
          <app-validation-error [errors]="form.get('taxType').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>


    <div class="col-12 col-md-6">
      <!-- @TODO Alex: Create custom form control for bank-account-select -->
      <mat-form-field>
        <mat-label>Bank account</mat-label>

        <mat-select formControlName="bankAccount" [compareWith]="compareWith" placeholder="Select bank account">
          <ng-container *ngIf="bankAccounts$ | async as bankAccounts">
            <mat-option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount">
              {{bankAccount.accountName}}
            </mat-option>
            <mat-option *ngIf="!bankAccounts.length">
              You don't have any added bank accounts right now.
            </mat-option>
          </ng-container>
        </mat-select>

        <mat-error>
          <app-validation-error [errors]="form.get('bankAccount').errors"></app-validation-error>
        </mat-error>

      </mat-form-field>
    </div>

    <!-- @TODO Alex TT-2715 waiting for design -->
    <!--<app-file-uploader label="Upload logo"-->
    <!--                   formControlName="file"-->
    <!--                   [fileTypes]="['image']"-->
    <!--                   [maxFileSizeMb]="10">-->
    <!--</app-file-uploader>-->
  </div>
</form>
