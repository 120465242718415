import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TankTypeSelectComponent
} from '@shared/component/form-controls/select/tank-type-select/tank-type-select.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule } from '@angular/forms';
import { ValidationErrorModule } from '@shared/component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [TankTypeSelectComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    ValidationErrorModule,
    MatRadioModule,
    MatTooltipModule
  ],
  exports: [
    TankTypeSelectComponent
  ]
})
export class TankTypeSelectModule { }
