<mat-form-field>
  <mat-label>{{label}}</mat-label>

  <input matInput
         [errorStateMatcher]="errorStateMatcher"
         [matAutocomplete]="auto"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         type="number"
         (input)="onChange(+$event.target.value)"
         (blur)="onTouched()">

  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="onChange($event.option.value)">

    <mat-option *ngFor="let value of options" [value]="value">
      {{value === 0 ? 'Upon issue (0 days)' : (value + ' days')}}
    </mat-option>
  </mat-autocomplete>

  <span matSuffix class="pl-5">days</span>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
