import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { incomeSourceFields } from '@client/shared/components/income-source/income-source-other-income/income-source-fields';
import { IncomeSourceComponent } from '@client/shared/components/income-source/income-source.component';
import { Observable, forkJoin } from 'rxjs';
import { plainToClass } from 'class-transformer';
import {
  FinancialYear,
  IncomeSource,
  IncomeSourceCollection,
  IncomeSourceForecast,
  IncomeSourceForecastTrustTypeEnum,
  IncomeSourceType,
  IncomeSourceTypeEnum,
  compareMatOptions,
  conditionalValidator
} from 'taxtank-core';
import { map } from 'rxjs/operators';

/**
 * Component that contains other incomes form and actions to work with it
 */
@Component({
  selector: 'app-income-source-other-income',
  templateUrl: './income-source-other-income.component.html',
  styleUrls: ['../income-source.component.scss']
})
export class IncomeSourceOtherIncomeComponent extends IncomeSourceComponent implements OnInit {
  // list of salary income sources (some other incomes like bonuses and director fees related with salary income source)
  salaryIncomeSources$: Observable<IncomeSource[]>;
  form: UntypedFormArray;
  types: IncomeSourceTypeEnum[] = [IncomeSourceTypeEnum.OTHER, IncomeSourceTypeEnum.WORK];
  displayedFormIndex = 0;
  compareFn = compareMatOptions;
  trustTypes: typeof IncomeSourceForecastTrustTypeEnum = IncomeSourceForecastTrustTypeEnum;
  urlFragment = 'otherIncome';

  ngOnInit(): void {
    super.ngOnInit();
    this.salaryIncomeSources$ = this.incomeSourceService.get()
      .pipe(
        map((incomeSources: IncomeSource[]) => new IncomeSourceCollection(incomeSources).getSalary())
      );
  }

  /**
   * Add salary form group with controls
   */
  addFormGroup(incomeSource: IncomeSource): void {
    const newGroup: UntypedFormGroup = this.fb.group({
      name: [incomeSource.name, Validators.required],
      type: [incomeSource.type],
      incomeSourceForecasts: this.fb.array(
        incomeSource.incomeSourceForecasts.map((incomeSourceForecast: IncomeSourceForecast) => this.fb.group({
          financialYear: [incomeSourceForecast.financialYear || new FinancialYear().year],
          incomeSource: [{value: incomeSourceForecast.incomeSource, disabled: true}, Validators.required],
          incomeSourceType: [incomeSourceForecast.incomeSourceType, Validators.required],
          amount: [{value: incomeSourceForecast.amount, disabled: true}, Validators.required],
          tax: [{value: incomeSourceForecast.tax, disabled: true}],
          trustType: [{value: incomeSourceForecast.trustType, disabled: true},
            conditionalValidator(
              (control: AbstractControl): boolean => control.enabled,
              Validators.required
            )],
          taxInstalments: [{value: incomeSourceForecast.taxInstalments, disabled: true}],
          frankingCredits: [{value: incomeSourceForecast.frankingCredits, disabled: true}],
        }))
      ),
    });

    this.form.push(newGroup);
    // Setup income source forecasts fields
    this.subscribeToIncomeSourceTypeChanges(newGroup);
    this.setupForecastFields(incomeSource.incomeSourceForecasts[0].incomeSourceType, newGroup);
    // Setup income source fields based on 'Payer' field type
    this.setupPayerFields(incomeSource.incomeSourceForecasts[0].incomeSourceType, newGroup);

    // Select created salary form
    this.selectForm(this.form.controls.length - 1);
  }

  /**
   * Subscribe to incomeSourceType form control
   * @param group with desired incomeSourceType form control
   */
  subscribeToIncomeSourceTypeChanges(group: UntypedFormGroup): void {
    const incomeSourceForecastsFormArray: UntypedFormArray = group.get('incomeSourceForecasts') as UntypedFormArray;

    incomeSourceForecastsFormArray.controls[0].get('incomeSourceType').valueChanges
      .subscribe((incomeSourceType: IncomeSourceType) => {
        this.setupForecastFields(incomeSourceType, group);
        this.setupPayerFields(incomeSourceType, group);
      });
  }

  /**
   * Setup 'Payer' form group field based on selected other income type
   * @param incomeSourceType: selected other income type
   * @param group: current form group
   */
  setupPayerFields(incomeSourceType: IncomeSourceType, group: UntypedFormGroup): void {
    const incomeSourceForecastsControl: UntypedFormGroup = group.get('incomeSourceForecasts')['controls'][0];

    if (incomeSourceType?.isBonuses()) {
      group.get('name').disable();
      incomeSourceForecastsControl.get('incomeSource').enable();
    } else {
      group.get('name').enable();
      incomeSourceForecastsControl.get('incomeSource').disable();
    }
  }

  /**
   * Setup forecast additional form fields
   * @param incomeType
   * @param group with desired incomeSourceType form control
   */
  setupForecastFields(incomeType: IncomeSourceType, group: UntypedFormGroup): void {
    const incomeSourceForecastsControl: UntypedFormGroup = group.get('incomeSourceForecasts')['controls'][0];

    Object.keys(incomeSourceForecastsControl.controls).forEach((key: string) => {
      // disable field if it's not 'incomeSourceType' or 'financialYear'
      if (key !== 'id' && key !== 'incomeSourceType' && key !== 'financialYear') {
        incomeSourceForecastsControl.get(key).disable({emitEvent: false});
      }
    });

    if (!incomeType) {
      return;
    }

    incomeSourceFields[incomeSourceForecastsControl.get('incomeSourceType').value.name].forEach((fieldName: string) => {
      incomeSourceForecastsControl.get(fieldName).enable({emitEvent: false});
    });
  }

  /**
   * Send income sources data
   */
  sendData(): void {
    const incomeSources: IncomeSource[] = [];
    const forecasts: IncomeSourceForecast[] = [];
    this.form.value
      .forEach((tabValue: object, index: number) => {
        // initial income source forecast (if exists)
        const initialForecast: IncomeSourceForecast = this.incomeSources[index]?.incomeSourceForecasts[0];
        // forecast to add, based on initial forecast and form tab value
        const forecastToAdd: IncomeSourceForecast = Object.assign(plainToClass(IncomeSourceForecast, {}), initialForecast, tabValue['incomeSourceForecasts'][0]);
        forecasts.push(forecastToAdd);

        // If current tab's other income type is NOT salary type - push it into 'incomeSources' array.
        if (!tabValue['incomeSourceForecasts'][0].incomeSourceType.isBonuses()) {
          incomeSources.push(Object.assign(plainToClass(IncomeSource, {}), this.incomeSources[index], tabValue, {incomeSourceForecasts: [forecastToAdd]}));
        }
      });

    const batch: Array<Observable<object>> = this.setupBatchToSend(forecasts, incomeSources);

    forkJoin(batch).subscribe((incomeSourceResponse: Array<IncomeSource[]>) => {
      // @TODO Alex: show message via toast service, remove string (waiting for services refactoring)
      this.toastService.success('Income source data saved');
      this.submitted.emit(incomeSourceResponse);
    });
  }

  /**
   * Setup batch with observables based on income sources / forecasts array length
   * @param forecasts
   * @param incomeSources
   */
  private setupBatchToSend(forecasts: IncomeSourceForecast[], incomeSources: IncomeSource[]): Array<Observable<object>> {
    const batch: Array<Observable<object>> = [];

    const forecastsToAddBonuses: IncomeSourceForecast[] = forecasts
      .filter((forecast: IncomeSourceForecast) => !forecast.id)
      .filter((forecast: IncomeSourceForecast) => forecast.incomeSourceType.isBonuses());

    const forecastsToUpdate: IncomeSourceForecast[] = forecasts.filter((forecast: IncomeSourceForecast) => forecast.id);

    const incomeSourcesToAdd: IncomeSource[] = incomeSources.filter((incomeSource: IncomeSource) => !incomeSource.id);
    const incomeSourcesToUpdate: IncomeSource[] = incomeSources.filter((incomeSource: IncomeSource) => incomeSource.id);

    // if new forecasts exist - push it to the batch
    if (forecastsToAddBonuses.length) {
      batch.push(this.incomeSourceForecastService.addBatch(forecastsToAddBonuses));
    }

    // if forecasts to update exist - push it to the batch
    if (forecastsToUpdate.length) {
      batch.push(this.incomeSourceForecastService.updateBatch(forecastsToUpdate));
    }

    // if new income sources exist - push it to the batch
    if (incomeSourcesToAdd.length) {
      batch.push(this.incomeSourceService.addBatch(incomeSourcesToAdd));
    }

    // if income sources to update exist - push it to the batch
    if (incomeSourcesToUpdate.length) {
      batch.push(this.incomeSourceService.updateBatch(incomeSourcesToUpdate));
    }

    return batch;
  }
}
