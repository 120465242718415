import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ViewPortService } from '@shared/services/view-port.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared/component/confirm-dialog/confirm-dialog.component';

/**
 * @TODO Alex/Artem refactor: 1. looks bad when added to absolute elements 2. inputs should have a similar name format
 * directive adds confirm popup
 */
@Directive({
  selector: '[appConfirmPopup]'
})
export class ConfirmPopupDirective {
  // message text that will be displayed in the popup
  @Input() appConfirmPopup: string;
  @Input() dialogCaption = 'Confirmation required';
  @Input() isDialog: boolean;
  @Input() acceptLabel = 'Confirm';
  @Input() rejectLabel = 'Cancel';
  @Output() accepted: EventEmitter<void> = new EventEmitter<void>();
  @Output() rejected: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private viewPortService: ViewPortService,
    private dialog: MatDialog
  ) {
  }

  @HostListener('click', ['$event'])
  onClick(event): void {
    // will confirm material dialog instead of confirm popup
    if (this.viewPortService.isMobile || this.isDialog) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: this.appConfirmPopup,
          caption: this.dialogCaption
        },
        panelClass: 'dialog-confirm'
      });

      dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
        if (!isConfirmed) {
          this.rejected.emit();
          return;
        }
        this.accepted.emit();
      });

      return;
    }

    this.confirmationService.confirm({
      target: event.target,
      acceptLabel: this.acceptLabel,
      rejectLabel: this.rejectLabel,
      message: this.appConfirmPopup,
      accept: () => {
        this.accepted.emit();
      },
      reject: () => {
        this.rejected.emit();
      }
    });
  }
}
