import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyInputComponent } from './currency-input.component';
import { ValidationErrorModule } from '@shared/component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@NgModule({
  declarations: [
    CurrencyInputComponent
  ],
  exports: [
    CurrencyInputComponent
  ],
  imports: [
    CommonModule,
    ValidationErrorModule,
    InputNumberModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatLegacyFormFieldModule
  ]
})
export class CurrencyInputModule {
}
