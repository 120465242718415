<div class="bg-light p-20">

  <div class="align-h-space-between">
    <div class="total-annual">
      <b>Total annual kms</b>
      (
      max {{kmsLimit}}
      <i matTooltip="The limit is common for all businesses and work" class="icon-info"></i>
      )
    </div>
    <div class="max-limit"><b>{{kmsLimit}}</b></div>
  </div>

  <mat-slider [max]="kmsLimit"
              [thumbLabel]="true"
              [disabled]="disabled"
              [(ngModel)]="value"
              class="color-success"
              (change)="onSlide($event.value)">
  </mat-slider>

</div>
