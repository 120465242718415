import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from 'taxtank-core';

/**
 * @TODO vik will be removed after redesign (checklist)
 * protects onboarding page from access of clients/firms who already finished it
 */
@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard  {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.check(state);
  }

  private check(state: RouterStateSnapshot): boolean {
    const user = this.userService.getCacheFirst();
    if (state.url.includes('onboarding') && !user.isOnboarding()) {
      this.router.navigateByUrl(user.isClient() ? '/client' : '/firm');
    }

    return true;
  }
}
