import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnChanges, ViewEncapsulation } from '@angular/core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginator, MatPaginatorDefaultOptions, MatPaginatorIntl } from '@angular/material/paginator';
import { MaterialModule } from '@shared/material/material.module';
import { MATERIAL_CONFIG } from '@shared/material/material-config';
import { NgForOf, NgIf } from '@angular/common';

/**
 * UI Component: update paginator (based on mat-paginator)
 */
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  imports: [
    MaterialModule,
    NgIf,
    NgForOf
  ],
  host: {
    'class': 'mat-mdc-paginator',
    'role': 'group',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [{ provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.paginator }]
})
export class PaginatorComponent extends MatPaginator implements OnChanges {
  constructor(
    intl: MatPaginatorIntl,
    changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    @Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) defaults?: MatPaginatorDefaultOptions
  ) {
    super(intl, changeDetectorRef, defaults);
  }

  ngOnChanges(): void {
    if (this.length <= this.pageSizeOptions[0]) {
      this.elementRef.nativeElement.style.display = 'none';
    } else {
      this.elementRef.nativeElement.style.display = 'block';
    }
  }
}
