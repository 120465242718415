<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'arrow'">
    <i *ngIf="rateValue"
       class="icon icon-arrow-{{ rateValue > 0 ? 'up rate-up' : 'down rate-down'}}"
       [class.reversed]="isReversed">
    </i>
  </ng-container>

  <ng-container *ngSwitchCase="'textArrow'">
    <div class="with-text">
      <span *ngIf="textFormat === 'textShort'">{{preText}} is {{ rateValue > 0 ? 'up' : 'down' }}</span>
      <span *ngIf="textFormat === 'textFull'">{{preText}} is {{ rateValue > 0 ? 'up' : 'down' }} by {{ rateValue | abs | number:'1.0-2' }}%
          <i *ngIf="rateValue"
             class="icon icon-arrow-{{ rateValue > 0 ? 'up rate-up' : 'down rate-down'}}"
             [class.reversed]="isReversed">
          </i>
        </span>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <span *ngIf="textFormat === 'textShort'">{{preText}} is {{ rateText }}</span>
    <span *ngIf="textFormat === 'textFull'">{{preText}} is {{ rateText }} by {{ rateValue | abs | number:'1.0-2'}}%</span>
  </ng-container>
</ng-container>
