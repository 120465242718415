<ng-container [appPreloader]="[endpoints.USER_CURRENT_PASSWORD_PUT]" [preloaderType]="preloaderTypes.OVERLAY">
  <form [formGroup]="form" (ngSubmit)="changePassword()">
    <div class="col-12 col-md-7 offset-md-2">
      <div class="description align-v-center mb-25">
        <div>
          <i class="description-icon icon icon-lock ic-primary is-big"></i>
        </div>
        <div>
          Your payment details are stored and encrypted by Stripe, who have a stellar record for high security!
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 offset-0 offset-md-3">
        <mat-form-field>
          <mat-label>Current password</mat-label>
          <input matInput type="password" formControlName="currentPassword" placeholder="Enter Your Current Password"
                 required>
          <mat-error *ngIf="form.get('currentPassword').invalid">
            <app-validation-error [errors]="form.get('currentPassword').errors"></app-validation-error>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 offset-0 offset-md-3">
        <div class="row">
          <app-password-matcher [form]="form" passwordFieldName="newPassword"></app-password-matcher>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-3 offset-0 offset-md-3">
        <button class="primary inverted icon" type="submit">
          <i class="icon icon-revert"></i>
          Change Password
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-3 offset-0 offset-md-3 offset-lg-0">
        <!-- @Todo Implement Reset MFA logic -->
        <!--<button class="primary inverted icon">-->
        <!--  <i class="icon icon-security"></i>-->
        <!--  Reset MFA-->
        <!--</button>-->
      </div>
    </div>
  </form>
</ng-container>
