import { Component, Input } from '@angular/core';
import { TermsPrivacyDialogComponent } from '@shared/components/terms/terms-privacy-dialog/terms-privacy-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'taxtank-core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() isWithLogout = false;
  // current year to show it in copyright section
  currentYear: number = new Date().getFullYear();

  constructor(
    public dialog: MatDialog,
    private authentificationService: AuthService,
  ) { }

  openTermsPrivacyDialog(): void {
    const dialogRef = this.dialog.open(TermsPrivacyDialogComponent);
  }

  logout(): void {
    this.authentificationService.logoutFront();
  }
}
