<!-- @Todo TT-2340 Mikhail Create common component with markup and use in each banner via slots -->
<header mat-dialog-title>
  <div>
    <h1 class="c-white mb-15">Upgrade with Work Tank</h1>
    <span class="fs-big">
     To manage your work with TaxTank please upgrade your subscription today.
    </span>
  </div>
  <button mat-dialog-close class="icon-close"></button>
</header>

<div mat-dialog-content>
  <div class="banner-image"></div>
  <div class="content-wrapper">
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Smart Tax Tools</span>
    </div>
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Vehicle Logbooks</span>
    </div>
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Live Bank Feeds</span>
    </div>

    <div class="pv-10">
      <b>$9/m</b>
    </div>

    <button class="bg-secondary c-white" (click)="openSubscriptionDialog()">Upgrade now</button>
  </div>
</div>
