<form [formGroup]="form" class="transparent" (ngSubmit)="save()" appFormErrorScroll>
  <mat-tab-group class="multiple-form-tab-group" [selectedIndex]="1" #tabGroup>

    <!-- The first disabled tab with add form button -->
    <mat-tab [disabled]="true">
      <ng-template matTabLabel>
        <button id="onBoardingPropertyAdd"
                appPropertiesLimit
                [addablePropertiesAmount]="form.controls.length"
                class="primary link"
                type="button"
                (click)="form.addProperty(); tabGroup.selectedIndex = form.controls.length">
          <i class="fa fa-plus-circle"></i>
          Add Property
        </button>
      </ng-template>
    </mat-tab>

    <!-- Tabs with properties forms -->
    <mat-tab *ngFor="let group of form.controls; let i = index">
      <ng-template matTabLabel>
        <div [class.c-error]="group.touched && !group.valid && isSubmitFired">
          {{'Property ' + (i + 1)}}
        </div>
        <button *ngIf="form.controls.length > 1"
                class="icon icon-button icon-close"
                (click)="form.removeAt(i)">
        </button>
      </ng-template>

      <ng-template matTabContent>
        <app-property-add-form [form]="group"></app-property-add-form>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
</form>
