<mat-form-field [class]="inputClass">

  <mat-label class="with-action">
    <div class="align-h-space-between">
      <span>{{ label }}</span>

      <button *ngIf="canAdd"
              appStopPropagation
              type="button"
              class="icon icon-plus link"
              (click)="openAddItemDialog()">
        Add bankAccount
      </button>
    </div>
  </mat-label>

  <mat-select [multiple]="multiple"
              [disabled]="disabled"
              [errorStateMatcher]="errorStateMatcher"
              [placeholder]="placeholder"
              [compareWith]="compareOptions"
              [(ngModel)]="value"
              (selectionChange)="onSelect($event.value)"
              (blur)="onTouched()">

    <ng-container *ngIf="options">

      <!-- Toggle all checkbox -->
      <mat-checkbox *ngIf="multiple"
                    [checked]="value?.length === options.length"
                    [indeterminate]="isIndeterminate"
                    class="bank-account-select-toggle-all-checkbox"
                    (change)="toggleAll($event.checked)">
        Select All
      </mat-checkbox>

      <!-- Group mode -->
      <ng-container *ngIf="groupBy">
        <mat-option *ngIf="hasEmptyOption" [value]="false">{{emptyOptionLabel}}</mat-option>
        <mat-optgroup *ngFor="let groupName of groupedOptions.keys" [label]="groupName">
          <mat-option *ngFor="let bankAccount of groupedOptions.get(groupName)" [value]="bankAccount">
            {{bankAccount.accountName}}
          </mat-option>
        </mat-optgroup>
      </ng-container>

      <!-- List mode -->
      <ng-container *ngIf="!groupBy">
        <mat-option *ngIf="hasEmptyOption" [value]="false">{{emptyOptionLabel}}</mat-option>
        <mat-option [value]="bankAccount" *ngFor="let bankAccount of options">
          {{bankAccount.accountName}}
        </mat-option>
      </ng-container>

      <!-- Empty list -->
      <mat-option [value]="false" *ngIf="!options.length" [disabled]="true">
        {{emptyMessage}}
      </mat-option>
    </ng-container>

  </mat-select>

  <div matSuffix>
    <app-preloader *ngIf="isLoading" [type]="preloaderTypes.ICON" [size]="20"></app-preloader>
  </div>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
