<header mat-dialog-title>
  <h2 class="dialog-caption">{{ !!invoiceTemplate.id ? 'Edit invoice template' : 'New invoice template' }}</h2>
  <button mat-dialog-close class="icon-close"></button>
</header>

<div mat-dialog-content>
  <app-invoice-template-form #invoiceTemplateFormComponent
                             [invoiceTemplate]="invoiceTemplate"
                             (saved)="dialogRef.close()">
  </app-invoice-template-form>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button mat-dialog-close class="basic w-100">Cancel</button>
    </div>
    <div class="col-6 col-sm-auto">
      <button type="submit" class="primary w-100" [appFormSubmit]="invoiceTemplateFormComponent">Save</button>
    </div>
  </div>
</div>
