<ng-container [appPreloader]="[
                endpoints.BANK_ACCOUNTS_GET,
                endpoints.BANK_ACCOUNTS_POST,
                endpoints.BANK_CONNECTION_POST
              ]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<div mat-dialog-title class="bank-connections-dialog-content">
  <div class="dialog-caption" [title]="connection.bank.name">
    <div class="align-v-center">
      <app-image [src]="connection.bank | call:'getPhoto'" [size]="40" format="square"></app-image>
      <div class="ml-10 bank-name">{{connection.bank.name}}</div>
    </div>
  </div>
  <button mat-dialog-close class="icon-close"></button>
</div>

<app-bank-accounts-import *ngIf="connection.externalId"
                          #formComponent
                          [connection]="connection"
                          class="h-100 f-column"
                          (back)="back.emit()"
                          (submitted)="save($event)">
</app-bank-accounts-import>

<app-bank-account-manual-form *ngIf="!connection.externalId"
                              #formComponent
                              [connection]="connection"
                              class="h-100 f-column"
                              (back)="back.emit()"
                              (submitted)="save($event)"
                              (connectionSaved)="connectionSaved.emit($event)">
</app-bank-account-manual-form>
