import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../base/base.component';

/**
 * Component that display photo of object that contains photo
 */
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent extends BaseComponent {
  @Input() src: string;
  /**
   * @TODO replace with size types (xs, s, m, l, xl)
   */
  @Input() size = 40;
  @Input() format: 'circle' | 'square' = 'circle';
  /**
   * @TODO Alex: add {required: true} and remove default value when Angular updated to v16
   */
  @Input(/* { required: true } */) placeholder: 'user' | 'property' | 'firm' | 'business' | 'bank' = 'user';
}
