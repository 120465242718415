<mat-toolbar color="primary">
  <div class="align-h-space-between align-v-center w-100">
    <div class="align-v-center">
      <img src="assets/img/icons/logo-white.svg"
           [routerLink]="['/client/dashboard']"
           title="logo"
           alt="logo"
           class="header-logo pointer"/>

      <ng-content select="[slot='left']"></ng-content>
    </div>

    <div class="align-v-center">
      <ng-content select="[slot='center']"></ng-content>
    </div>

    <div class="align-v-center">
      <ng-content select="[slot='right']"></ng-content>
    </div>
  </div>
</mat-toolbar>
