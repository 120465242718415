<header mat-dialog-title>
  <h2 class="dialog-caption">{{business ? 'Edit' : 'Add'}} business</h2>
  <button mat-dialog-close class="icon-close"></button>
</header>

<div mat-dialog-content>
  <app-sole-business-form #soleBusinessFormComponent
                          [business]="business"
                          (saved)="dialogRef.close()">
  </app-sole-business-form>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button mat-dialog-close class="basic w-100">Cancel</button>
    </div>
    <div class="col-6 col-sm-auto">
      <button [appFormSubmit]="soleBusinessFormComponent" class="primary w-100">Save</button>
    </div>
  </div>
</div>
