<mat-sidenav-container [class.hidden]="user.isOnboarding()" class="sidenav-container">
  <mat-sidenav #snav
               [mode]="viewPortService.isMobile ? 'over' : 'side'"
               [fixedInViewport]="viewPortService.isMobile"
               [opened]="!viewPortService.isMobile"
               [class.mobile]="viewPortService.isMobile"
               [class.collapsed]="!snav.opened"
               [autoFocus]="false"
               (click)="viewPortService.isMobile && snav.close()"
               opened="true">

    <ng-content select="[slot='beforeMenu']"></ng-content>

    <app-menu [menuItems]="menuItems"></app-menu>
  </mat-sidenav>

  <mat-sidenav-content [class.collapsed]="!snav.opened"
                       [class.mobile]="viewPortService.isMobile">
    <div class="layout-content-container">
      <ng-content></ng-content>
    </div>

    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
