import { Component, Input } from '@angular/core';
import { Phone, PhoneForm, PhoneTypeEnum } from 'taxtank-core';
import { AbstractFormInputComponent } from '@shared/component/form-controls/form-input/abstract-form-input/abstract-form-input.component';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent extends AbstractFormInputComponent<Phone, PhoneForm> {
  @Input() showTypeField = false;
  @Input() hideRequiredMarker: boolean;
  formModel: typeof PhoneForm = PhoneForm;
  phoneTypes: typeof PhoneTypeEnum = PhoneTypeEnum;
}
