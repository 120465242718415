import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  BankAccount,
  BankAccountAddManualForm, BankAccountTypeEnum, BankConnection,
  BankConnectionService, TankTypeEnum,
  User, UserService,
} from 'taxtank-core';
import { BaseFormComponent } from '@shared/component/base/base-form.component';

/**
 * Form component which create a single manual (not basiq) bank account
 */
@Component({
  selector: 'app-bank-account-manual-form',
  templateUrl: './bank-account-manual-form.component.html',
  styleUrls: ['./bank-account-manual-form.component.scss'],
  // custom styles for properties and loan forms
  encapsulation: ViewEncapsulation.None
})
export class BankAccountManualFormComponent extends BaseFormComponent implements OnInit {
  @Input() connection: BankConnection;
  @Output() submitted: EventEmitter<BankAccount[]> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() connectionSaved: EventEmitter<BankConnection> = new EventEmitter<BankConnection>();
  form: BankAccountAddManualForm;

  tankTypes = TankTypeEnum;
  bankAccountTypes = BankAccountTypeEnum;
  user: User;

  constructor(
    private bankConnectionService: BankConnectionService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = this.userService.getCacheFirst();
    super.ngOnInit();
  }

  buildForm(): void {
    this.form = new BankAccountAddManualForm(this.connection);
  }

  save(bankAccount: BankAccount): void {
    if (this.connection.id) {
      bankAccount.bankConnection = this.connection;
      this.submitted.emit([bankAccount]);
    } else {
      // if user selected a new bank connection we have to save it first to get it's id
      this.bankConnectionService.post(this.connection).subscribe(((connection: BankConnection) => {
        bankAccount.bankConnection = connection;
        this.submitted.emit([bankAccount]);
        this.connectionSaved.emit(connection);
      }));
    }
  }
}
