<!--@TODO Artem replace isMobile checkings with global styles or use the same styles for all cases -->
<div [class.hover-background-primary]="!viewPortService.isMobile" class="align-v-center">
  <mat-form-field class="compact no-error year-chooser">
    <mat-label></mat-label>
    <input type="text"
           placeholder="Select client"
           matInput
           (input)="filterUsers($event.target.value)"
           [value]="currentUser?.fullName"
           (click)="$event.stopPropagation()"
           [matAutocomplete]="auto">
    <mat-autocomplete (optionSelected)="switch($event)" #auto="matAutocomplete">
      <mat-option *ngFor="let client of filteredClients" [value]="client">
        {{ client.fullName }}
      </mat-option>
    </mat-autocomplete>
    <i matPrefix [class.ic-white]="!viewPortService.isMobile" class="icon is-default icon-user-account"></i>
    <i matSuffix [class.ic-white]="!viewPortService.isMobile" class="fa fa-chevron-down"></i>
  </mat-form-field>
</div>
