import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Address, AddressForm, AddressTypeEnum, CorelogicService, CorelogicSuggestion, StatesEnum } from 'taxtank-core';
import { AbstractFormInputComponent } from '@shared/component/form-controls/form-input/abstract-form-input/abstract-form-input.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent extends AbstractFormInputComponent<Address, AddressForm> implements OnInit {
  @Input() hideTypeField = true;
  @Input() hideCountryField = true;
  @Input() hideRequiredMarker: boolean;
  addressTypes: typeof AddressTypeEnum = AddressTypeEnum;
  states: typeof StatesEnum = StatesEnum;
  formModel: typeof AddressForm = AddressForm;
  suggestions$: Observable<CorelogicSuggestion[]>;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private corelogicService: CorelogicService
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.form.onSearch.subscribe((searchQuery: string) => {
      this.suggestions$ = this.corelogicService.getSuggestions(searchQuery)
        .pipe(
          map((suggestions: CorelogicSuggestion[]) => {
            // Preselect the first suggestion to get corelogic location for manual search when corelogic data is required (property case)
            if (this.form.isManual) {
              this.form.onSelectSuggestion(suggestions[0]);
              // {emitEvent: false} required to avoid unnecessary search after suggestion preselected
              this.form.get('searchQuery').setValue(suggestions[0]?.suggestion, { emitEvent: false });
            }

            return suggestions;
          })
        );
    });
  }

  /**
   * helper-function for mat-autocompleter. Display selected suggestion as human-readable string.
   */
  displaySuggestionFn(suggestionObject: CorelogicSuggestion | string): string {
    if (typeof suggestionObject === 'string' && !!suggestionObject) {
      return suggestionObject;
    }

    if (!suggestionObject) {
      return '';
    }

    return (suggestionObject as CorelogicSuggestion).suggestion;
  }
}
