<mat-form-field [class]="inputClass">
  <mat-label class="with-action">
    <div class="align-h-space-between">
      <span>{{ label }}</span>

      <button *ngIf="canManage" appStopPropagation type="button" class="icon icon-plus link" (click)="openManageDialog()">
        Add Asset
      </button>
    </div>
  </mat-label>

  <input #searchInput
         matInput
         [errorStateMatcher]="errorStateMatcher"
         [matAutocomplete]="auto"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         type="text"
         (input)="onInput($event.target.value)"
         (blur)="onTouched()"/>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event.option.value)" [displayWith]="displayFn">

    <ng-container *ngIf="filteredOptions?.length">
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{ option.name }}
        <!-- same stock could be traded on multiple exchanges -->
        <ng-container *ngIf="option.isShare()">({{ exchangesById.get(option.exchange.id).name }}: {{ option.ticker }})</ng-container>
      </mat-option>
    </ng-container>

    <mat-option *ngIf="!filteredOptions.length" [disabled]="true">
      No results found
    </mat-option>
  </mat-autocomplete>

  <div matSuffix>
    <ng-container [appPreloader]="[endpoints.HOLDING_TYPES_GET]"
                  [preloaderType]="preloaderTypes.ICON"
                  [preloaderSize]="20">
    </ng-container>
  </div>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
