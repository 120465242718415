import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, MatExpansionModule } from '@angular/material/expansion';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS, MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS as MAT_PAGINATOR_DEFAULT_OPTIONS, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MAT_LEGACY_PROGRESS_SPINNER_DEFAULT_OPTIONS as MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { OverlayModule } from '@angular/cdk/overlay';
import { MATERIAL_CONFIG } from '@shared/material/material-config';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

@NgModule({
  imports: [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatTabsModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatTooltipModule,
    OverlayModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatStepperModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatTreeModule,
    MatChipsModule
  ],
  exports: [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatTabsModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    OverlayModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatStepperModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatTreeModule,
    MatChipsModule
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_CONFIG.dateFormat },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.expansionPanel },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.label },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.dialog },
    // { provide: MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.legacyDialog },
    { provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.progressSpinner },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.paginator },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: MATERIAL_CONFIG.ripple }
  ]
})
export class MaterialModule {
}
