import { ChangeDetectorRef, Component, Input, Self } from '@angular/core';
import { ControlValueAccessor, FormControlName, NgControl } from '@angular/forms';
import { BaseComponent } from '@shared/component/base/base.component';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Base class for custom form controls.
 * Custom form controls works only with ngModel or with formControl/formControlName.
 * Details with implementation: https://blog.angular-university.io/angular-custom-form-controls/
 */
@Component({
  template: ''
})
export abstract class AbstractControlComponent<Model> extends BaseComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() label = '';
  @Input() inputClass = '';
  value: Model;
  errorStateMatcher: ErrorStateMatcher = null;

  constructor(
    // https://tyapk.ru/blog/post/angular-di-decorators
    @Self() public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
    // https://unicorn-utterances.com/posts/angular-components-control-value-accessor
    ngControl.valueAccessor = this;

    // Handle error state and touch logic if control is a part of FormGroup.
    // No need to do it for ngModel data binding.
    if (ngControl instanceof FormControlName) {
      this.errorStateMatcher = {
        isErrorState: (): boolean => ngControl.touched && ngControl.invalid
      };

      ngControl.formDirective.ngSubmit.subscribe(() => {
        this.onTouched();
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  /**
   * This method should be called when this.value changed to notify forms API to update form's state.
   * First overwrite this.value, second call this.onChange()
   */
  onChange(value: Model): void {}

  /**
   * When a form value changes due to user input, we need to report the value back to the parent form.
   * This is done by calling a callback, that was initially registered with the control using the registerOnChange method
   */
  registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  /**
   * This method should be called when you want to mark form control as touched.
   */
  onTouched(): void {}

  /**
   * When the user first interacts with the form control, the control is considered to have the status touched, which is useful for styling.
   * In order to report to the parent form that the control was touched, we need to use a callback registered using the registerOnToched method
   */
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  /**
   * form controls can be enabled and disabled using the Forms API.
   * This state can be transmitted to the form control via the setDisabledState method
   */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * This method is called by the Forms module to write a value into a form control
   */
  writeValue(value: Model): void {
    this.value = value;
  }
}
