<ng-container [appPreloader]="[endpoints.STRIPE_CHECKOUT_SESSION_POST,
                               endpoints.USER_CURRENT_GET,
                               endpoints.SERVICE_PRICES_GET,
                               endpoints.SUBSCRIPTION_LAST_GET,
                               endpoints.PRORATION_COST_POST,
                               endpoints.SUBSCRIPTION_ITEMS_PUT,
                               endpoints.PRORATION_COST_POST,
                               endpoints.SERVICE_SUBSCRIPTIONS_POST]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<div mat-dialog-title>
  <div class="dialog-caption">{{subscription?.id ? 'Update' : 'Change'}} plan</div>
  <div class="dialog-subtitle">
    Select tanks that match your incomes. Pay only for what you need, no lock in contracts and 100% tax deductible!
  </div>
  <button mat-dialog-close class="icon-close"></button>
</div>

<div mat-dialog-content>
  <div class="annual-subscription">
    <div class="annual-subscription-toggler">
      <div>Monthly</div>
      <mat-slide-toggle class="primary" (change)="setPrices($event.checked)" [checked]="isAnnual"></mat-slide-toggle>
      <div>Annually</div>
      <div class="annual-discount">Save 12%</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-5">
      <div *ngFor="let product of products" class="product"
           [class.active]="checkbox.checked"
           (click)="toggleProduct(product)">
        <div class="align-h-space-between">
          <div class="mt-5">
            <div class="align-v-center">
              <mat-checkbox appPreventDefault #checkbox [checked]="subscription?.hasProduct(product)"></mat-checkbox>
              <h5 class="c-secondary fw-normal ml-10">{{product.title}}</h5>
              <i class="icon-info ic-text ml-5" [matTooltip]="tooltips[product.id]"></i>
            </div>
          </div>

          <div class="fs-default c-secondary baseline mt-5">
            <h5 [class.line-through]="subscription.promoCode?.percentOff"
                class="c-secondary d-inline fw-600">
              {{(pricesByProduct.get(product.id).amount * (product.isProperties() ? this.propertiesQty : 1)) | customCurrency}}
            </h5>

            <ng-container *ngIf="subscription.promoCode?.percentOff">
              <h5 class="ml-5 c-secondary d-inline fw-600">
                {{pricesByProduct.get(product.id).discountPrice(subscription.promoCode) * (product.isProperties() ? this.propertiesQty : 1) | customCurrency}}
              </h5>
            </ng-container>

            /{{ frequency }}
          </div>
        </div>

        <div class="align-h-right c-text">
          <ng-container [ngSwitch]="true">
            <ng-container *ngSwitchCase="product.isProperties()">
              Includes {{this.propertiesQty}} properties
            </ng-container>
            <ng-container *ngSwitchCase="product.isHoldingsTank()">
              Unlimited holdings and trades
            </ng-container>
            <ng-container *ngSwitchCase="product.isSoleTank()">
              Up to 6 businesses
            </ng-container>
          </ng-container>
        </div>
      </div>
      <!-- All Tanks -->
      <div class="all-products">
        <div class="align-v-center">
          <mat-slide-toggle class="primary"
                            appPreventDefault
                            (click)="togglePackage(!isPackage)"
                            [checked]="isPackage">
          </mat-slide-toggle>
          <h5 class="c-secondary fw-normal ml-10">All Tanks</h5>
          <i class="icon-info ic-text ml-5" [matTooltip]="tooltips.all"></i>
        </div>

        <div class="fs-default c-secondary text-right">
          <div class="text-right">
            <h5 [class.line-through]="subscription.promoCode"
                class="c-secondary d-inline">
              {{packageSum | customCurrency}}
            </h5>

            <ng-container *ngIf="subscription.promoCode">
              <h5 class="ml-5 c-secondary d-inline fw-600">
                {{ packageSum - subscription.discountAmount | customCurrency }}
              </h5>
            </ng-container>

            /{{ frequency }}
          </div>
          <div class="status-badge approved mt-10">Extra discount 15%</div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-7">
      <div class="bg-light p-15 c-secondary">
        <h5 class="c-secondary fw-bold mb-20">
          <ng-container *ngIf="subscription?.items.length">
            <ng-container *ngFor="let item of subscription?.items; let i = index">
              {{products.findBy('id', item.price.product.id).title}}
              <span *ngIf="i < subscription?.items.length - 1"> + </span>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!subscription?.items.length">Please select products</ng-container>
        </h5>

        <div *ngIf="subscription?.propertyTankItem">
          <div class="fs-big mb-10">Need more than 5 properties? Select how many you need here.</div>
          <div class="align-v-center">
            <div class="quantity-selector">
              <!--            @TODO prime form control-->
              <p-inputNumber [(ngModel)]="propertiesQty"
                             [showButtons]="true"
                             buttonLayout="horizontal"
                             [min]="propertiesMinQty"
                             [max]="propertiesMaxQty"
                             incrementButtonIcon="fa fa-plus c-secondary"
                             decrementButtonIcon="fa fa-minus c-secondary"
                             (ngModelChange)="updatePropertiesQuantity()">
              </p-inputNumber>
            </div>
            <div class="ml-10">{{ propertyPrice.amount }}/{{ frequency }} for each additional property</div>
          </div>
        </div>

        <div class="row gy-3 mt-20">
          <div *ngFor="let feature of features" class="col-12 col-lg-6">
            <div class="align-v-center">
              <!-- @TODO Alex: bad performance -->
              <i *ngIf="featuresJoin?.includes(feature)" class="icon-check ic-success mr-10"></i>
              <i *ngIf="!featuresJoin?.includes(feature)" class="icon-close ic-error mr-10"></i>
              {{feature}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 c-text mt-15">
      When trialing multiple tanks, a subscription to one tank will not end other trials (excludes All Tanks).
    </div>

  </div>
</div>

<div mat-dialog-actions>
  <div class="row">
    <div class="col-auto">
      <div [class.invisible]="!subscription.promoCode">
        <div class="fs-default c-secondary">Promo code: -{{ subscription.discountAmount | customCurrency}}</div>
      </div>
      <div class="fs-extra-big fw-bold c-secondary">
        Total: {{ subscription?.price | customCurrency }}/{{ frequency }}
      </div>
    </div>

    <div class="col-auto" *ngIf="!subscription.id">
      <!-- promo codes allowed only for new subscriptions -->
      <app-promo-code [subscription]="subscription" [firstPayment]="firstPayment"></app-promo-code>
    </div>

    <div class="col-12 col-sm">
      <div class="align-h-right">
        <button (click)="dialogRef.close()" class="basic compact mr-10">Cancel</button>

        <button [disabled]="!subscription?.items.length"
                name="continue"
                class="primary m-0"
                (click)="subscription.id ? openChangeSubscriptionConfirmPopup($event) : postSubscription()">
          Upgrade subscription
        </button>
      </div>
    </div>
  </div>
</div>

<!-- @TODO Alex: show preloader by endpoints listen -->
<app-preloader *ngIf="isLoading" [type]="preloaderTypes.OVERLAY"></app-preloader>

