<!-- @TODO implement smarter solution for validation errors -->
<span *ngIf="errors && errors['unique']">Already exists</span>
<span *ngIf="errors && errors['email']">Email format is wrong</span>
<span *ngIf="errors && errors['emailIsUsed']">Email already used</span>
<span *ngIf="errors && errors['abnIsUsed']">ABN already used</span>
<span *ngIf="errors && errors['tanIsUsed']">TAN already used</span>
<span *ngIf="errors && errors['phoneInvalid']">Phone number format is invalid. Valid characters: 0-9, (, ), -</span>
<!-- Password -->
<span *ngIf="errors && errors['passwordWeak']">Password is too weak</span>
<span *ngIf="errors && errors['passwordInvalid']">Password format is invalid</span>
<span *ngIf="errors && errors['passwordMismatch']">Passwords don't match</span>
<!-- Min/max -->
<span *ngIf="errors && errors['min']">Value should be greater than {{ errors['min'].min }}</span>
<span *ngIf="errors && errors['max']">Value should not be greater than {{ errors['max'].max }}</span>
<span *ngIf="errors && errors['greaterThan']">{{errors['greaterThan']}}</span>
<!-- Date -->
<span *ngIf="errors && errors['minDate']">{{ errors['minDate'] }}</span>
<span *ngIf="errors && errors['dateRangeStart']">The date should not be before {{errors['dateRangeStart'] | date}}</span>
<span *ngIf="errors && errors['dateRangeEnd']">The date should not be after {{errors['dateRangeEnd'] | date}}</span>

<!-- Max symbols -->
<span *ngIf="errors && errors['maxlength']">Amount of characters must not exceed {{errors['maxlength'].requiredLength}}</span>
<!-- String length -->
<span *ngIf="errors && errors['requiredLength']">Invalid length. {{errors['requiredLength']}} symbols required</span>
<!-- File -->
<span *ngIf="errors && errors['fileMaxSize']">File is too big. Maximum file size is {{ errors['fileMaxSize'].maxSize | fileSizeConvert }}</span>
<span *ngIf="errors && errors['fileExtensions']">The type of the file is invalid. Allowed file types: {{ errors['fileExtension'].allowedExtensions }}</span>
<!-- Less than fields comparison -->
<span *ngIf="errors && errors['lessThanValidation']">
  {{ errors['lessThanValidation'].secondControl.alias || (errors['lessThanValidation'].secondControl.name | decamelize) }}
  should be greater than
  {{ errors['lessThanValidation'].firstControl.alias || (errors['lessThanValidation'].firstControl.name | decamelize) }}
</span>
<!-- Other -->
<span *ngIf="errors && errors['notFromList']">Please select a value from the list</span>
<!-- Date -->
<span *ngIf="errors && errors['notCurrentFinYear']">Date is out of the current financial year</span>
<span *ngIf="errors && errors['matDatepickerMin']">Date should be greater than {{ errors['matDatepickerMax'].min | date }}</span>
<span *ngIf="errors && errors['matDatepickerMax']">Date should not be greater than {{ errors['matDatepickerMax'].max | date }}</span>
<!-- Properties -->
<span *ngIf="errors && errors['addressIsUsed']">This address is already active in your portfolio!</span>
<!-- Fields summary -->
<span *ngIf="errors && errors['fieldsSum']">
  The sum of the '{{ errors['fieldsSum'].alias || (errors['fieldsSum'].name | decamelize) }}' fields exceeds {{ errors['fieldsSum'].summary }}
</span>
<!-- Common sum validator -->
<span *ngIf="errors && errors['commonSum']">
  {{errors['commonSum']}}
</span>
<!-- Required -->
<span *ngIf="errors && errors['required']">Field is required</span>
