import { Component, Input } from '@angular/core';
import {
  LoanBankTypeEnum,
  LoanForm,
  LoanInterestTypeEnum,
  LoanRepaymentFrequencyEnum,
  LoanRepaymentTypeEnum
} from 'taxtank-core';
import { ViewPortService } from '@shared/services/view-port.service';

/**
 * Bank account loan form.
 * Allow user to add bank account's loan information.
 * Using in bank accounts add/import process.
 */
@Component({
  selector: 'app-bank-account-loan-form',
  templateUrl: './bank-account-loan-form.component.html',
  styleUrls: ['./bank-account-loan-form.component.scss']
})
export class BankAccountLoanFormComponent {
  @Input() form: LoanForm;
  loanTypes: typeof LoanBankTypeEnum = LoanBankTypeEnum;
  loanRepaymentFrequencies: typeof LoanRepaymentFrequencyEnum = LoanRepaymentFrequencyEnum;
  loanRepaymentTypes: typeof LoanRepaymentTypeEnum = LoanRepaymentTypeEnum;
  loanInterestTypes: typeof LoanInterestTypeEnum = LoanInterestTypeEnum;

  constructor(public viewPortService: ViewPortService) {
  }
}
