<nav mat-tab-nav-bar
     [class.block]="isBlock"
     [ngClass]="{
        'primary': color === 'primary',
        'secondary': color === 'secondary',
        'light': color === 'light',
        'transparent': color === 'transparent'
      }">

  <swiper #swiper class="navigation-carousel" [config]="carouselConfig">
    <ng-template *ngFor="let link of links; let i = index" swiperSlide>
      <a (click)="slideToActiveTab(i)"
         mat-tab-link
         routerLinkActive
         #rla="routerLinkActive"
         [attr.id]="link.id"
         [active]="rla.isActive"
         [routerLink]="[link.url]">
        {{link.label}}
      </a>
    </ng-template>
  </swiper>
</nav>
