<!-- @TODO Alex/Vik: Think how to avoid template copypast -->
<mat-form-field [class]="inputClass">
  <mat-label class="with-action align-h-space-between">
    <div>
      {{label}}
      <ng-content select="[slot='tooltip']"></ng-content>
    </div>

    <button *ngIf="canManage"
            appStopPropagation
            type="button"
            class="link"
            (click)="openManageDialog()">
      Manage Custom
    </button>
  </mat-label>

  <input #searchInput
         matInput
         [errorStateMatcher]="errorStateMatcher"
         [matAutocomplete]="auto"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         [matAutocompletePosition]="matAutocompletePosition"
         type="text"
         (input)="onInput($event.target.value)"
         (blur)="onTouched()">

  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="onSelect($event.option.value)">

    <ng-container *ngIf="filteredOptions.length">
      <!-- Group mode -->
      <ng-container *ngIf="groupBy">
        <mat-optgroup *ngFor="let groupName of sortedKeys" [label]="groupName">
          <mat-option *ngFor="let option of groupedOptions.get(groupName)" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-optgroup>
      </ng-container>

      <!-- List mode -->
      <ng-container *ngIf="!groupBy">
        <mat-option [value]="option" *ngFor="let option of filteredOptions">
          {{option.name}}
        </mat-option>
      </ng-container>
    </ng-container>

    <!-- Empty list -->
    <mat-option *ngIf="searchInput.value.length >= minSearchLength && !filteredOptions.length" [disabled]="true">
      No results found
    </mat-option>

  </mat-autocomplete>

  <div matSuffix>
    <app-preloader *ngIf="isLoading" [type]="preloaderTypes.ICON" [size]="20"></app-preloader>
  </div>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
