<ng-container [appPreloader]="[endpoints.INCOME_SOURCES_POST,
                               endpoints.INCOME_SOURCES_PUT,
                               endpoints.INCOME_SOURCE_FORECAST_POST,
                               endpoints.INCOME_SOURCE_FORECAST_PUT]"
              [preloaderType]="preloaderTypes.OVERLAY">
  <div *ngIf="form">
    <div class="title align-v-center m-15">
      <i class="icon icon-wallet mr-15 is-big ic-secondary"></i>
      <div>
        <h5>Create payers and forecasts</h5>
        <div>A payer name could be a Ticker or Investment name, and forecasts can be updated anytime to estimate your tax correctly</div>
      </div>
    </div>

    <div class="tabs">
      <div *ngIf="!incomeSource.id"
           id="onBoardingOtherIncomeAdd"
           class="tab-add"
           (click)="addFormGroup()">
        <i class="fa fa-plus-circle"></i>
        Add Income
      </div>

      <div class="tab d-flex align-v-center"
           *ngFor="let group of form.controls; let i = index"
           [class.active]="i === displayedFormIndex"
           (click)="selectForm(i)">
        {{group.get('incomeSourceForecasts').controls[0]?.get('incomeSourceType').value?.name || 'Other Income ' + (i + 1)}}
        <button *ngIf="form.controls.length > 1"
                appStopPropagation
                (click)="removeForm(i)"
                class="icon-close">
        </button>
      </div>
    </div>

    <form [formGroup]="form" appFormErrorScroll>
      <ng-container *ngFor="let group of form.controls; let i = index">
        <ng-container [formGroup]="group" *ngIf="i === displayedFormIndex">
          <div class="row">

            <div class="col-12 col-md-6" *ngIf="group.get('name').enabled">
              <mat-form-field>
                <mat-label>Payer</mat-label>
                <div id="onBoardingOtherIncomePayerAdd">
                <input matInput formControlName="name" placeholder="Enter Payer Name">
                </div>
                <mat-error *ngIf="group.get('name').invalid">
                  <app-validation-error [errors]="group.get('name').errors"></app-validation-error>
                </mat-error>
              </mat-form-field>
            </div>

            <ng-container formArrayName="incomeSourceForecasts">
              <ng-container
                *ngFor="let otherIncomeControl of group.get('incomeSourceForecasts')['controls']; let i = index">
                <ng-container [formGroup]="otherIncomeControl">

                  <div class="col-12 col-md-6" *ngIf="otherIncomeControl.get('incomeSource').enabled">
                    <mat-form-field>
                      <mat-label>Payer</mat-label>
                      <mat-select formControlName="incomeSource"
                                  [placeholder]="(salaryIncomeSources$ | async).length ? 'Select Payer' : 'You have no salary incomes. Please add at least one!'"
                                  [compareWith]="compareFn">
                        <mat-option *ngFor="let salaryIncomeSource of (salaryIncomeSources$ | async)"
                                    [value]="salaryIncomeSource">
                          {{salaryIncomeSource.name}}
                        </mat-option>
                        <div matSuffix *ngIf="!incomeSources">
                          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                        </div>
                      </mat-select>

                      <mat-error *ngIf="otherIncomeControl.get('incomeSource').invalid">
                        <app-validation-error [errors]="otherIncomeControl.get('incomeSource').errors"></app-validation-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- order-first class is used to put "Income type" before "Income name" and not use it outside the formGroup-->
                  <div class="col-12 col-md-6 order-first">
                    <mat-form-field>
                      <mat-label>Income Type</mat-label>
                      <mat-select
                        id="onBoardingOtherIncomeTypeSelect"
                        [compareWith]="compareFn" required
                        formControlName="incomeSourceType"
                        placeholder="Select Type Of Income">
                          <mat-option *ngFor="let type of incomeTypes" [value]="type">
                            {{type.name}}
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="otherIncomeControl.get('incomeSourceType').invalid">
                        <app-validation-error [errors]="otherIncomeControl.get('incomeSourceType').errors"></app-validation-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-6" *ngIf="otherIncomeControl.get('amount').enabled">
                    <app-currency-input min="0"
                                        formControlName="amount"
                                        label="Annual Income"
                                        placeholder="Enter Annual Forecast">
                    </app-currency-input>
                  </div>

                  <div class="col-6" *ngIf="otherIncomeControl.get('tax').enabled">
                    <app-currency-input min="0"
                                        formControlName="tax"
                                        label="Tax Withheld"
                                        placeholder="Enter Tax Withheld Amount">
                    </app-currency-input>
                  </div>

                  <div class="col-6" *ngIf="otherIncomeControl.get('taxInstalments').enabled">
                    <mat-form-field>
                      <mat-label>Tax Instalments</mat-label>
                      <input matInput type="number" formControlName="taxInstalments"
                             placeholder="Enter Tax Instalments Amount">
                      <mat-error *ngIf="otherIncomeControl.get('taxInstalments').invalid">
                        <app-validation-error [errors]="otherIncomeControl.get('taxInstalments').errors"></app-validation-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-6" *ngIf="otherIncomeControl.get('trustType').enabled">
                    <mat-form-field>
                      <mat-label>Trust type</mat-label>
                      <mat-select formControlName="trustType"
                                  placeholder="Select type">
                        <mat-option *ngFor="let trustType of trustTypes | ekeyvalue | sortBy:'value' :'desc'" [value]="+trustType.key">
                          {{ trustType.value | translate }}
                        </mat-option>
                      </mat-select>

                      <mat-error *ngIf="otherIncomeControl.get('trustType').invalid">
                        <app-validation-error [errors]="otherIncomeControl.get('trustType').errors"></app-validation-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-6" *ngIf="otherIncomeControl.get('frankingCredits').enabled">
                    <mat-form-field>
                      <mat-label>Franking Credits</mat-label>
                      <input matInput type="number" formControlName="frankingCredits"
                             placeholder="Enter Franking Credits Forecast">
                      <mat-error *ngIf="otherIncomeControl.get('frankingCredits').invalid">
                        <app-validation-error [errors]="otherIncomeControl.get('frankingCredits').errors"></app-validation-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
</ng-container>
