<div class="progress-spinner"
     [class.error]="value < 30"
     [class.warning]="value >= 30 && value < 60"
     [class.info]="value >= 60">

  <mat-progress-spinner
    mode="determinate"
    [diameter]="diameter"
    [value]="value">
  </mat-progress-spinner>

  <label>{{value | number:'1.0-0'}}%</label>
</div>
